.collapsed-list {
  margin: -2rem 0 2rem;
  position: relative;
}

.collapsed-item {
  border-bottom: 1px solid #e2e7f0;
}

.collapsed {
  color: $color-secondary;
  font-weight: bold;
  padding: 3rem 0;
  position: relative;

  @include align-items(center);
  @include flexbox;
  @include justify-content(space-between);

  h3 {
    //font-family: $font-general;
    font-size: 2rem;
    letter-spacing: normal;
    max-width: 80%;
    padding: 0;
  }

  &::after {
    background-color: transparent;
    background-image: url('/images/icons/plus-icon.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 2.5rem;
    border-radius: 4rem;
    content: '';
    display: inline-block;
    height: 4rem;
    position: relative;
    right: 0;
    width: 4rem;

    @include transition(all 0.3s);
  }

  &.open {
    &::after {
      @include transform(rotate(45deg));
    }
  }

  &:hover,
  &:focus {
    cursor: pointer;

    &::after {
      background-color: #f4f4f7;
    }
  }
}

.collapsed-wrapper {
  height: 0;
  overflow: hidden;
  
  @include transition(height ease 0.3s);
}

.collapsed-content {
  max-width: 80%;
  padding-bottom: 3rem;
}

@media screen and (max-width: 820px) {
  .collapsed-content {
    max-width: 100%;
  }

  .collapsed h3 {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 600px) {
  .collapsed {
    padding: 2rem 0;
  }

  .collapsed::after {
    width: 3rem;
    height: 3rem;
    background-size: 2rem;
  }

  .collapsed-list {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 480px) {
  .collapsed h3 {
    font-size: 1.6rem;
  }
}

.product-description {
  margin-bottom: 1rem;
  max-height: 7rem;
  min-height: 7rem;
  overflow: hidden;
  text-align: center;
}

.text-page {
  overflow-x: hidden;
  padding: 8.5rem 0;
  position: relative;
  text-align: center;

  &.center-text {
    h1,
    h2 {
      text-align: center;
      padding-left: 2rem;
      padding-right: 2rem;
    }

    h1+p,
    h2+p,
    .center {
      text-align: center;
      max-width: 40rem;
      margin-left: auto;
      margin-right: auto;
      padding-left: 2rem;
      padding-right: 2rem;
    }

    h1+p, .center {
      max-width: 70rem;
      margin-top: 2rem;
    }

    form p {
      margin-bottom: 1rem;
    }
  }

  p {
    font-size: 1.8rem;
    line-height: 2.4rem;
  }

  h1 {
    padding: 3.5rem 0 0;
  }

  h4 {
    font-size: 1.8rem;
    font-weight: bold;
    margin: 4rem 0 2rem;
  }

  .accent-color {
    color: $color-secondary;
  }

  .underline {
    text-decoration: underline;
  }

  ol {
    margin-bottom: 2rem;
    padding-left: 2rem;

    li {
      line-height: 2.2rem;
      margin-bottom: 1rem;
    }

    a {
      color: $color-secondary;
      font-weight: 700;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  ul,
  ol {
    list-style: disc;
    margin-bottom: 2rem;
    margin-top: 1rem;
    padding-left: 2rem;

    li {
      font-size: 1.8rem;
      line-height: 2.4rem;
      margin-bottom: 1rem;
    }

    a {
      color: $color-secondary;
      font-weight: 700;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  table {
    margin: 3rem 0;

    th {
      background-color: #323232;
      color: #fff;
      overflow: hidden;
      text-align: left;
      text-overflow: ellipsis;
      vertical-align: top;
      width: auto;
    }

    tr:nth-child(even) {
      td {
        background-color: #eee;
      }
    }

    td {
      color: #323232;
      overflow: hidden;
      text-align: left;
      text-overflow: ellipsis;
      vertical-align: top;
      width: 25%;
    }
  }

  .text-page-title h1 {
    margin-left: auto;
    margin-right: auto;
    max-width: 80rem;
    padding-bottom: 0.1em;
    width: 90%;
  }

  .text-link {
    display: block;
    font-weight: bold;
    margin: 0 auto 4rem;
    padding: 0 2rem;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .title-section {
    margin: 4rem auto;
    max-width: 70rem;
  }

  .title-text {
    font-size: 1.8rem;
    line-height: 1.5;
    margin: 4rem auto;
    max-width: 70rem;
  }

  h1,
  p,
  a,
  section {
    position: relative;
    z-index: 2;
  }

  .content {
    margin: 0 auto;
    max-width: 900px;
    padding: 5rem 0 0;
    text-align: left;

    p a {
      color: $color-secondary;
      font-weight: 700;
      text-decoration: underline;
      word-break: break-all;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .hero-btn {
    margin: 2rem 0;
  }
}

.text-container {
  margin: 5rem auto;
  padding: 0 2rem;

  .container {
    background: #fff;
    border-radius: 1rem;
    max-width: calc(1280px - 4rem);
    padding: 6rem 9rem;
    text-align: left;
  }

  ul {
    list-style: disc;
    margin-bottom: 2rem;
    margin-top: 1rem;
    padding-left: 2rem;

    li {
      margin-bottom: 1.5rem;
    }
  }

  ol {
    padding-left: 2rem;

    li {
      margin-bottom: 1.5rem;
    }
  }

  .title-block {
    margin-bottom: 4rem;

    @include flexbox;
    @include justify-content(space-between);

    h2 {
      //font-family: $font-general;
      font-size: 3.2rem;
      letter-spacing: normal;
      padding-top: 0;

      @include flex-grow(1);
    }

    h3 {
      //font-family: $font-general;
      font-size: 1.8rem;
      letter-spacing: normal;
      padding-top: 0;
    }

    .button-wrapper {
      margin-left: 5%;
    }
  }

  .button-wrapper {
    width: 22rem;

    button {
      width: 100%;
    }
  }
}

@media screen and (max-width: 991px) {
  .text-container h3 {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 820px) {
  .text-page {
    text-align: left;
    padding-bottom: 4rem;

    .text-page-title h1 {
      margin: 0;
      max-width: 70rem;
    }

    .title-text {
      margin: 2rem;
      max-width: 80%;
      text-align: left;
      font-size: 1.6rem;
    }

    .content {
      padding: 5rem 0 2rem;
    }
  }

  .text-container {
    margin: 5rem 0;

    .container {
      border-radius: 1.5rem;
      padding: 4rem 5rem;
    }

    .title-block {
      margin-bottom: 2rem;

      @include flex-direction(column);

      .button-wrapper {
        margin-left: 0;
      }

      h2 {
        font-size: 2.4rem;
      }

      h3 {
        font-size: 1.6rem;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .text-page {
    padding-bottom: 2rem;

    .content {
      padding: 3rem 0 2rem;
    }
  }

  .text-container {
    margin: 2rem 0 3rem;

    .container {
      padding: 3rem 2rem;
    }

    .title-block {
      h2 {
        font-size: 2rem;
      }

      h3 {
        font-size: 1.4rem;
      }
    }
  }
}

@media screen and (max-width: 420px) {
  .text-page table td {
    font-size: 1.2rem;
  }

  .text-page .table-wrapper {
    overflow-x: auto;
  }
}

@media screen and (max-width: 360px) {
  .text-page {
    .text-page-title h1 {
      margin: 0;
      max-width: 100%;
      width: 100%;
    }
  }

  .text-container {
    margin: 2rem 0;

    h2 {
      padding-top: 0;
      padding-bottom: 0;
    }

    .title-block h3 {
      padding-bottom: 0;
      padding-top: 2rem;
    }

    .container {
      border-radius: 1rem;
      padding: 3rem 2rem 2rem;
    }
  }
}
// @import '@hyex/ui/styles/hyex/variables.module.scss';

.search {
  .content {
    margin-top: 15rem;
  }

  h1.title {
    padding: 4rem 0;

    &:before {
      display: none;
    }
  }

  h2.title {
    padding-top: 5rem;
    padding-bottom: 2rem;

    @media screen and (max-width: 580px) {
      padding-top: 4rem;
    }
  }

  .subtitle {
    padding: 2rem 0 4em;

    @media screen and (max-width: 580px) {
      padding-top: 0;
      padding-bottom: 2rem;
    }
  }

  .search-section {
    border-top: 1px solid #e6e6e6;
  }

  .search-result {
    margin-bottom: 4rem;
  }

  .category-section {
    border: 0;
  }
}

.site-search {
  &.shown {
    .search-dropdown {
      height: auto;
      opacity: 1;
      padding: 4rem 0 6rem;
      display: block;
    }
  }

  .form-inline {
    margin: 0 auto;
    width: 100%;
    max-width: 80rem;

    @include flexbox;
    @include justify-content(center);
    @include align-items(center);
  }

  .search-dropdown {
    background-color: #ffffff;
    border-radius: 0;
    border-top: 1px solid #e6e6e6;
    box-shadow: 0 1rem 2.5rem rgba(14, 19, 88, 5%);
    display: none;
    height: 0;
    left: 0;
    max-width: 100vw;
    opacity: 0;
    overflow: hidden;
    padding: 0;
    position: fixed;
    right: 0;
    top: 8.5rem;
    transition: opacity 0.3s ease-in-out;
    width: 100vw;
    z-index: 1000;

    .btn {
      background-color: transparent !important;
      background-image: url('/images/icons/search-icon.svg');
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: contain;
      border: 0;
      border-radius: 0;
      color: transparent;
      cursor: pointer;
      display: block;
      height: 3rem;
      margin: 0;
      padding: 0;
      width: 3rem;

      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }

      @media (max-width: 580px) {
        height: 2.2rem;
        width: 2.2rem;
      }
    }

    input {
      background-color: transparent;
      border: 0;
      border-bottom: 1px solid $color-secondary;
      border-radius: 0;
      color: $color-secondary;
      font-size: 2.25rem;
      font-weight: 400;
      line-height: normal;
      margin-right: 1rem;
      outline: none;
      padding: 0;
      text-transform: none;
      width: 70%;

      @media (max-width: 767px) {
        font-size: 2rem;
      }

      @media (max-width: 580px) {
        font-size: 1.8rem;
        width: 80%;
      }

      &:focus {
        box-shadow: none;
        outline: none;

        &::placeholder {
          opacity: 1;
        }
      }

      &::placeholder {
        color: $color-secondary;
      }
    }

    .close {
      background-color: transparent;
      background-image: url('/images/icons/close-icon.svg');
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: contain;
      border: 0;
      border-radius: 0;
      color: transparent;
      cursor: pointer;
      display: block;
      height: 1.5rem;
      margin: 0;
      opacity: 0.4;
      padding: 0;
      position: absolute;
      right: 2rem;
      top: 2rem;
      width: 1.5rem;

      @media (max-width: 1280px) {
        right: 2.35rem;
      }

      @media (max-width: 580px) {
        right: 1.75rem;
      }

      &:hover {
        opacity: 0.6;
      }
    }
  }
}

.no-webp .site-search {
  .btn {
    background-image: url('/images/icons/search-icon.svg');
  }
}

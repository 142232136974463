//TODO: +-BASE

.learn {
  .content {
    margin-top: 15rem;
  }
}

.category-section {
  padding-bottom: 2rem;

  &:first-child {
    border: none;
  }
}

.article-banner-section {
  background-color: $color-light-bg;

  padding: 6rem 0;

  h4 {
    padding-bottom: 0;
  }
}

.article-info {
  margin-top: 2rem;
}

.reviewed {
  color: #696f68;

  img {
    margin-right: 0.6rem;
    position: relative;
    top: 0.2rem;
    opacity: 0.8;
  }
}

.article-options {
  background: #fff;
  padding: 3rem 0;
  z-index: 101;

  .container {
    @include align-items(center);
    @include flexbox;
    @include justify-content(space-between);
  }
}

.share-links {
  @include align-items(center);
  @include flexbox;
  @include justify-content(flex-start);

  span {
    font-size: 1.6rem;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
    padding-bottom: 1rem;
    color: $color-primary;
    font-weight: 700;
    margin: 0 2rem 0 0;
    opacity: 1;
    padding-top: 1rem;

    &.mobile {
      display: none;
    }
  }

  button {
    margin: 0;
  }
}

.back-link {
  font-weight: bold;
  margin-right: 2rem;
  text-decoration: none;

  img {
    margin-right: 1rem;

    @include transition(all 0.3s);
  }

  &:hover {
    color: $color-secondary-dark;
    text-decoration: none;
    animation: none;

    img {
      transform: translateX(-0.5rem);
    }
  }

  &:focus,
  &:active,
  &.active {
    text-decoration: none;
    animation: none;
  }

  .mobile {
    display: none;
  }
}

.article-content {
  margin-bottom: 4rem;
  overflow: hidden;
  padding-top: 11rem;

  @include flexbox;
  @include justify-content(space-between);

  p,
  p span {
    font-size: 1.8rem;
    letter-spacing: -0.01rem;
    line-height: 2.4rem;
    margin-left: 0 !important;
    margin-right: 0 !important;
    text-align: left !important;
    text-indent: 0 !important;
  }

  .badges-block {
    position: absolute;
    left: 1.5rem;
    top: 1.5rem;
  }

  .article-author {
    margin-top: 4rem;
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
    display: flex;
    flex-direction: row;
    padding: 2.4rem 0;

    .prefix {
      width: 50%;
    }

    .avatar {
      margin-left: 1rem;
      flex: 0 0 130px;

      img {
        object-fit: cover;
        border-radius: 50%;
        height: 130px;
        width: 130px;
      }
    }

    .bio {
      padding-left: 2rem;
      line-height: 2.4rem;

      .full-bio {
        padding-top: 2rem;
        margin-bottom: 0;
        font-size: 1.6rem;
      }
    }

    @media screen and (max-width:600px) {
      flex-direction: column;

      .prefix {
        width: 100%;
      }

      .avatar {
        margin: 2rem auto 0;
      }

      .bio {
        padding-left: 0;
      }
    }
  }

  .product-price .badges-block {
    display: none;
  }

  &.center-content {
    .article-content-wrapper {
      margin: 0 auto;
    }
  }
}

.article-section {
  .content-container {
    position: relative;
    z-index: 10;
  }
}

.sticky-container>div:first-child {
  position: relative;
  z-index: 100;
}

.sticky-container {
  position: absolute;
  height: 93%;
  width: 100vw;
}

.sticky-container .product-wrapper,
.article-content .product-wrapper {
  background: #faf9fa;
  border-radius: 0.5rem;
  padding: 4rem 2rem;
  text-align: center;
  position: relative;
  height: auto;

  button {
    font-size: 1.4rem;
  }

  a {
    color: $color-primary;
  }

  h3 {
    color: $color-primary;
    font-size: 2rem;
    padding-bottom: 1rem;
    text-align: center;
  }

  p {
    text-align: center !important;
    margin-bottom: 1rem;
    line-height: 2rem;
    font-size: 1.5rem;
    min-height: 0 !important;
  }

  .product-img-block {
    background: transparent;
  }

  &.vertical {
    padding: 0 0 4rem;

    @include flex-direction(column);
    @include flexbox;

    .cart-btn {
      margin: 0 auto;
    }

    .product-details {
      padding: 0 2rem;
    }

    .product-price {
      @include justify-content(center);
      padding: 0 2rem;
      margin: 1.5rem auto;
    }
  }

  &.horizontal {
    margin: 4rem 0;
    padding: 0;
    text-align: left;

    @include flex-direction(row-reverse);
    @include flexbox;
    @include justify-content(space-between);

    .product-img-block {
      max-width: 30rem;

      .badges-block {
        right: 1.5rem;
        left: auto;

        .product-badge {
          float: right;
        }
      }

      img {
        margin-bottom: 0;
      }
    }

    .product-details {
      padding-left: 2rem;

      h3,
      p {
        text-align: left !important;
      }

      .product-price {
        padding-top: 0;
      }

      p {
        max-width: 50rem;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.article-content-wrapper {
  margin-right: 4rem;
  max-width: 80rem;

  img {
    margin-bottom: 4rem;
    max-width: 100%;
    height: auto;
    display: block;
  }

  h3 {
    color: $color-primary;
    padding: 2rem 0;
  }

  h4 {
    color: #000;
    font-size: 2.2rem;
    letter-spacing: normal;
    padding: 2rem 0;
    text-transform: none;
  }

  .ref {
    // border-top: 1px solid #e6e6e6;
    font-size: 1.4rem !important;
    margin-top: 3rem;
    padding-top: 3rem;
    word-wrap: break-word;

    br {
      content: '';
      display: block;
      font-size: 24%;
      margin: 2em;
    }

    a {
      color: $color-secondary !important;
      font-size: 1.4rem !important;

      span {
        color: $color-secondary !important;
        font-size: 1.4rem !important;
        line-height: normal !important;
      }

      br {
        display: none;
        margin: 0;
        font-size: 0;
      }
    }

    p {
      font-size: 1.4rem !important;
      margin: 0 0 1rem;

      span {
        line-height: normal !important;
      }
    }
  }

  a {
    font-weight: bold;
  }

  hr {
    border: none;
    // border-top: 1px solid #e6e6e6;
    margin: 3rem 0;
  }

  ul {
    list-style: disc;
    margin-left: 2rem;

    li {
      display: list-item;
      list-style: disc;
      margin-bottom: 0.5rem;
    }
  }

  ol {
    list-style: decimal;
    margin-left: 3.5rem;

    li {
      display: list-item;
      margin-bottom: 0.5rem;
    }

    &:last-child {
      font-size: 90%;
      margin-left: 2.5rem;
      text-wrap: wrap;
    }

    &:has(+ .product-wrapper.horizontal) {
      font-size: 90%;
      margin-left: 0;
      text-wrap: wrap;
    }
  }

  small {
    font-size: 90%;

    ul,
    ol {
      margin: 0;
    }
  }
}

.article-panel-wrapper {
  align-self: flex-start;
  left: auto !important;
  position: absolute;
  right: calc((100vw - 1280px + 8rem) / 2) !important;
  width: 32rem !important;
  z-index: 40;
  padding-bottom: 0;
  @include flex(0 0 32rem);

  &.relative {
    position: relative !important;
  }

  @media (max-scroll: 200px) {
    position: relative !important;
    top: 0;
  }
}

@media screen and (max-width: 1280px) {
  .article-content-wrapper {
    max-width: calc(100vw - 44rem);
  }

  .article-panel-wrapper {
    right: 4rem !important;
  }
}

@media screen and (max-width: 820px) {
  .article-panel-wrapper {
    display: none !important;
  }

  .article-content-wrapper {
    margin-right: 0;
    max-width: 100%;

    img {
      margin-bottom: 3rem;
    }
  }

  .article-content {
    padding-top: 3rem;
  }

  .article-banner-section {
    padding: 3rem 0;

    h1 {
      padding: 0.5rem 0;
    }

    .article-info {
      margin: 1rem 0;
    }

    .reviewed {
      margin-bottom: 0;
    }
  }

  .article-options {
    padding: 1rem 0 !important;
  }
}

@media screen and (max-width: 600px) {

  .sticky-container .product-wrapper.horizontal,
  .article-content .product-wrapper.horizontal {
    padding-bottom: 2rem;

    @include flex-direction(column);

    .product-img {
      margin: 0 auto 2rem;
      max-width: 12.5rem;
    }

    .product-details {

      p,
      h3 {
        text-align: center !important;
      }
    }

    button {
      margin: 0 auto;
    }

    .product-price {
      @include justify-content(center);
    }
  }

  .share-links {
    span {
      &.desktop {
        display: none;
      }

      &.mobile {
        display: inline-block;
      }
    }
  }

  .back-link span {
    &.desktop {
      display: none;
    }

    &.mobile {
      display: inline-block;
    }
  }
}

@media screen and (max-width: 480px) {
  .share-links {
    span {
      &.mobile {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 360px) {

  .sticky-container .product-wrapper,
  .article-content .product-wrapper,
  .sticky-container .product-wrapper.horizontal,
  .article-content .product-wrapper.horizontal {
    padding: 2rem 1rem;

    h3 {
      font-size: 2.4rem;
    }
  }

  .back-link span {
    &.mobile {
      display: none;
    }
  }
}
.image-slider {
  height: calc(40vw - 9rem);
  max-height: 51rem;
  max-width: 60rem;
  width: 40vw;

  @include flexbox;

  .embla-carousel-thumb {
    height: 100%;
    min-width: 8rem;
  }

  .embla-carousel-main {
    padding-left: 1rem;

    @include flex-grow(1);
  }

  .carousel-main,
  .carousel-thumb {
    height: 100%;
    position: relative;
  }

  .carousel-main-viewport,
  .carousel-thumb-viewport {
    height: 100%;
    overflow: hidden;
  }

  .carousel-main-viewport {
    background-color: #faf9fa;
  }

  .carousel-main-container,
  .carousel-thumb-container {
    user-select: none;
    -khtml-user-select: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
  }

  .carousel-main-container {
    height: 100%;

    @include flexbox;
  }

  .carousel-thumb-container {
    display: block;
    height: 100%;
  }

  .carousel-main-slide {
    margin-left: 1rem;
    position: relative;
    padding: 10%;

    @include flex(0 0 100%);
    @include flexbox;
    @include justify-content(center);
    @include align-items(center);

    img {
      height: auto;
      object-fit: contain;
      width: auto;
      max-width: 100%;
      max-height: 100%;
      position: relative;
    }
  }

  .carousel-thumb-slide {
    background-color: #faf9fa;
    margin-bottom: 1rem;
    overflow: hidden;
    position: relative;
    padding: 10%;
    width: 100%;

    &:after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }
  }

  .carousel-thumb-slide-img {
    height: 80%;
    object-fit: contain;
    opacity: 0.2;
    position: absolute;
    width: 80%;
  }

  .carousel-thumb-slide-button {
    appearance: none;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    height: 100%;
    margin: 0;
    outline: 0;
    padding: 0;
    touch-action: manipulation;
    width: 100%;

    @include flexbox;
  }

  .carousel-thumb-slide--selected .carousel-thumb-slide-img {
    opacity: 1;
  }

  @media screen and (max-width: 1000px) {
    height: calc(100vw + 5rem);
    margin-left: auto;
    margin-right: auto;
    max-height: 69rem;
    position: relative;
    width: calc(100vw - 4rem);

    @include flex-direction(column-reverse);

    .embla-carousel-thumb {
      height: 8rem;
      min-width: auto;
      width: 100%;
    }

    .carousel-thumb,
    .carousel-thumb-viewport {
      height: 8rem;
    }

    .carousel-thumb-container {
      height: auto;
      margin-left: -1rem;
      width: auto;

      @include flexbox;
    }

    .carousel-main-slide {
      margin-left: 0;

      img {
        max-height: 49rem;
      }
    }

    .carousel-thumb-slide {
      margin-bottom: 0;
      margin-left: 1rem;
      padding: 1rem;
      max-height: 8rem;
      overflow: hidden;

      @include flex(0 0 8rem);
    }

    .embla-carousel-main {
      padding-bottom: 1rem;
      padding-left: 0;
    }
  }

  @media screen and (max-width: 640px) {
    .carousel-main-slide {
      img {
        max-height: calc(80vw - 4rem);
      }
    }
  }
}

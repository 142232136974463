.radio-label {
  cursor: pointer;
  display: inline-block;
  line-height: 2;
  margin-bottom: 0;
  margin-left: 1rem;
  padding: 1.5rem 0 1.5rem 3rem;

  &::before {
    background-color: transparent;
    border-radius: 10rem;
    border: 1px solid $color-primary;
    content: '';
    display: block;
    height: 2.3rem;
    left: 2.8rem;
    position: absolute;
    top: 2.2rem;
    transition: all 0.3s;
    width: 2.3rem;
  }

  &::after {
    border-radius: 0.6rem;
    content: '';
    display: block;
    height: 1.3rem;
    left: 3.3rem;
    position: absolute;
    top: 2.7rem;
    transition: all 0.3s;
    width: 1.3rem;
  }

  .option-text {
    color: $color-primary;
  }

  .option-price {
    color: $color-primary;
    position: absolute;
    right: 2.5rem;
  }

  .promo {
    color: $color-accent;
    display: block;
    line-height: 1.3;
    white-space: normal;
  }
}

.radio-label.active::after {
  background-color: $color-accent;
}

.radio-input {
  display: inline;
  left: 3rem;
  padding: 0;
  position: absolute;
  top: 0.5rem;
  vertical-align: middle;
  visibility: hidden;
  width: 1.6rem;
}

.radio-block {
  display: flex;

  margin: 0 -2rem 0.5rem;
  padding: 0 3rem 0 2rem;
  position: relative;
  justify-content: space-between;

  div {
    width: 55%;

    @media screen and (max-width: 820px) {
      width: 60%;
    }
  }
}

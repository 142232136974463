header {
  background: #ffffff;
  box-shadow: 0 1rem 2.5rem rgba(14, 19, 88, 5%);
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 200;

  .container {
    padding: 0;
  }

  nav {
    height: 8.5rem;
    padding: 1rem 3rem;
    position: relative;
    width: 100%;

    @media screen and (max-width: 820px) {
      padding: 1rem 2rem;
    }
  }

  .logo {
    left: 0;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    right: 0;
    width: 16rem;
    z-index: 301;

    img {
      height: 6.5rem;
      width: 13.8rem;
    }
  }

  .nav-wrapper {
    height: 6.5rem;

    @include align-items(center);
    @include flex-direction(row-reverse);
    @include flexbox;
    @include justify-content(space-between);
  }

  .nav-menu-bar {
    cursor: pointer;
    display: inline-block;
    height: 1.6rem;
    margin-right: 4rem;
    padding: 0;
    position: relative;
    top: 0.1rem;
    width: 2.4rem;

    @include flex(0 0 2.4rem);

    span {
      background: $color-secondary;
      border-radius: 2px;
      display: block;
      height: 2px;
      position: absolute;
      width: 100%;

      @include transition(top 0.2s 0.2s linear, opacity 0.1s 0.2s linear, transform 0.2s linear);

      &:nth-child(1) {
        top: 0;
      }

      &:nth-child(2) {
        top: 0.6rem;
      }

      &:nth-child(3) {
        top: 1.2rem;
      }
    }

    &.active span {
      top: 0.6rem;
      @include transition(top 0.2s linear, opacity 0.1s 0.2s linear, transform 0.2s 0.2s linear);

      &:nth-child(1) {
        @include transform(rotate(45deg));
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:nth-child(3) {
        @include transform(rotate(-45deg));
      }
    }
  }

  .nav-menu-list {
    background: #ffffff;
    height: 0;
    max-height: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 8.5rem;
    width: 100vw;

    &>div {
      margin-bottom: 2.5rem;
      display: none;
    }

    a {
      text-decoration: none;

      @include animation(none);
    }

    @include flex-direction(column);
    @include flexbox;
    @include transition(opacity 0.3s);

    &.active {
      background: #ffffff;
      box-shadow: 0 1rem 2.5rem rgba(14, 19, 88, 5%);
      height: calc(100vh - 8.5rem);
      max-height: none;
      left: 0;
      opacity: 1;
      overflow: auto;
      padding: 2rem 2rem;
      z-index: 200;

      @media screen and (min-width: 1280px) and (max-width: 1319px) {
        left: calc((-100vw + 1280px) / 2);
      }

      div {
        display: block;
      }
    }

    .nav-link {
      color: $color-primary;
      cursor: pointer;
      display: block;
      font-size: 1.6rem;
      font-weight: 600;
      letter-spacing: 0.05rem;
      padding: 1rem;
      position: relative;
      text-decoration: none;
      text-transform: uppercase;

      @include transition(all 0.3s);

      &:focus,
      &:hover {
        color: $color-secondary;
        text-decoration: none;

        @include animation(none);
      }

      &.active {
        background-color: $color-light-bg;
        border-radius: 0.5rem;
        color: $color-secondary;
        font-weight: 600;
      }

      .underline {
        text-decoration: underline;
      }
    }

    .nav-last-articles {
      display: none !important;
    }

    .nav-submenu-link-wrapper {
      a {
        @include animation(none);
      }

      h4 {
        color: $color-secondary;
        font-size: 1.4rem;
        font-weight: 600;
        padding-left: 0;
        position: relative;

        &::before {
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          content: '';
          display: block;
          height: 2.5rem;
          left: 0;
          position: absolute;
          top: -0.5rem;
          width: 2.5rem;
        }
      }

      .nav-link {
        display: block;
      }

      .submenu-wrapper {
        background: #ffffff;
        font-size: 1.6rem;
        margin-top: 1rem;
        margin-left: 2rem;

        &:after {
          background-color: $color-light-bg;
          content: '';
          display: block;
          height: 0.1rem;
          left: 0;
          position: fixed;
          right: 0;
          top: 8.5rem;
          width: 100%;
        }

        .nav-link {
          background-color: transparent;
          color: $color-primary;
          font-size: 1.6rem;
          font-weight: 500;
          padding: 1rem 0;
          text-transform: none;
        }
      }

      .links-block {
        margin: 0 0 2rem 2rem;
      }
    }
  }

  .header-wrapper {
    height: 2.5rem;

    @include align-items(center);
    @include flexbox;
    @include justify-content(center);
  }

  .search-btn {
    cursor: pointer;
    display: inline-block;
    height: 3.2rem;
    position: relative;
    top: 0.3rem;
    width: 2.2rem;

    &:hover {
      opacity: 0.7;
    }
  }

  .acc-link {
    position: absolute;
    left: 8rem;
    text-indent: -99999999999px;
    background-image: url('/images/icons/account-icon.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 45% auto;
    cursor: pointer;
    display: inline-block;
    height: 4rem;
    width: 4rem;
    white-space: nowrap;
    overflow: hidden;

    &:hover {
      opacity: 0.7;
    }
  }
}

@media screen and (min-width: 630px) {
  header {
    .nav-menu-bar {
      margin-right: 2rem;
    }

    .header-wrapper {
      .search-btn {
        margin-right: 1rem;
      }
    }
  }
}

@media screen and (max-width: 629px) {
  header {
    .header-wrapper {
      .search-btn {
        margin-right: 0;
      }
    }

    .acc-link {
      left: 7rem;
    }
  }
}

@media screen and (min-width: 1320px) {
  header {
    .nav-wrapper {
      @include flex-direction(row);
    }

    .nav-menu-bar {
      display: none;
    }

    .nav-menu-list {
      align-items: center;
      height: auto;
      min-height: fit-content;
      opacity: 1;
      overflow: visible;
      padding: 2rem 1rem;
      position: unset;
      width: fit-content;

      @include flex-direction(row);
      @include flexbox;

      &>div {
        display: block;
        margin-bottom: 0;

        &:not(:last-child) {
          margin-right: 5rem;
        }
      }

      .nav-link {
        font-size: 1.6rem;
        font-weight: normal;
        height: 3.5rem;
        letter-spacing: normal;
        padding: 1rem 0;

        &::before {
          background: linear-gradient(to right, $color-secondary, $color-accent);
          border-radius: 3px;
          bottom: 0;
          content: '';
          height: 3px;
          left: 0;
          position: absolute;
          width: 0;

          @include transition(all 0.3s);
        }

        &:hover {
          &::before {
            width: 100%;
          }
        }

        &.active {
          background: transparent;
        }
      }

      .nav-submenu-link-wrapper {
        position: relative;

        .nav-link {
          font-size: 1.6rem;

          &.active {
            background: transparent;
          }

          &::before {
            display: none;
          }
        }

        .links-block {
          margin-bottom: 1rem;
          margin-left: 0;
        }

        &::before {
          background: linear-gradient(to right, $color-secondary, $color-secondary);
          bottom: 0;
          content: '';
          height: 3px;
          left: 0;
          position: absolute;
          width: 0;

          @include transition(all 0.3s);
        }

        &:hover {
          &::before {
            width: 100%;
          }

          &::after {
            @include transform(rotate(180deg));
          }
        }

        .submenu-wrapper {
          margin-left: 0;
        }
      }

      .submenu-wrapper {
        box-shadow: 0 2.5rem 2.5rem rgba(14, 19, 88, 5%);
        left: 0;
        margin: 0;
        padding: 4.5rem 5rem 2rem;
        position: fixed;
        top: 5rem;
        width: 100vw;
        z-index: 300;

        &:after {
          background-color: $color-light-bg;
          content: '';
          display: block;
          height: 0.1rem;
          left: 0;
          position: absolute;
          right: 0;
          top: 2.5rem;
          width: 100%;
        }

        @include flexbox;

        .nav-link {
          border-radius: 1rem;
          color: $color-primary;
          display: block;
          font-weight: 400;
          padding: 1rem 0;

          &:hover {
            color: $color-secondary;
          }

          .underline {
            text-decoration: underline;
          }
        }

        .nav-full-width {
          padding-left: calc((100% - 1280px + 7.5rem) / 2);
          width: 100%;

          @include flexbox;

          &.line .nav-link {
            width: 30rem;

            &::after {
              background-image: url('/images/icons/next-arr.svg');
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
              content: '';
              display: inline-block;
              height: 1rem;
              margin-left: 1rem;
              position: relative;
              width: 1.8rem;

              @include transition(all 0.3s);
            }

            &:hover::after {
              margin-left: 1.3rem;
            }
          }
        }
      }

      .large-block {
        @include flexbox;

        .small-block {
          min-width: 20rem;
        }
      }

      .nav-last-articles {
        display: flex !important;
        margin-left: 7rem;
        margin-top: 1rem;

        @include justify-content(space-between);

        .nav-article {
          margin-left: 2rem;
          max-width: 30rem;

          a:hover {
            @include animation(animated-underline 0.3s ease-in-out forwards);
          }
        }

        .nav-article-img {
          font-size: 0;
          height: 11vw;
          letter-spacing: 0;
          margin-bottom: 1rem;
          max-height: 20rem;
          overflow: hidden;
          width: 100%;
          word-spacing: 0;

          a {
            display: block;
            height: 100%;
            width: 100%;

            img {
              min-height: 100%;
              min-width: 100%;
              object-fit: cover;
              object-position: center center;

              @include flex-shrink(0);
              @include transition(transform 0.45s ease-in-out, opacity 0.3s linear);
              @include transform(scale(1) translateZ(0));
            }

            &:hover {
              img {
                @include transition(transform 0.6s ease-out, opacity 0.3s !important);
                @include transform(scale(1.1) translateZ(0));
              }
            }
          }
        }

        .product-item {
          .product-image {
            padding: 0 60px;
          }

          &:hover {
            img {
              @include transition(transform 0.6s ease-out, opacity 0.3s !important);
              @include transform(scale(1.1) translateZ(0));
            }
          }
        }

        .nav-article-text h3 {
          font-size: 1.8rem;

          &.center {
            text-align: center;
          }
        }
      }
    }

    .acc-link {
      display: none;
    }
  }
}

@media screen and (max-width: 820px) {
  header {
    .nav-menu-list.active {
      left: 0;
      padding: 2rem;
    }

    .nav-menu-bar {
      margin-right: 2rem;
    }
  }
}

@media screen and (max-width: 480px) {
  header .logo {
    width: 4rem;
  }
}

@media screen and (max-width: 420px) {
  header {
    .nav-menu-bar {
      margin-right: 1.5rem;
    }
  }
}
.quantity-selector {
  margin-right: 4rem;

  @include align-items(center);
  @include flexbox;

  .quantity-selector-btn {
    border-radius: 100%;
    border: 1px solid #e6e6e6;
    padding: 1rem 1.5rem;

    @include align-items(center);
    @include flexbox;
    @include justify-content(center);

    svg {
      height: 1.5rem;
      width: 1.5rem;
    }
  }

  .current-quantity {
    background: transparent;
    border: none;
    display: inline-block;
    font-weight: 600;
    letter-spacing: normal;
    padding: 0;
    text-align: center;
  }
}

@mixin animation($values) {
  -webkit-animation: $values;
  -moz-animation: $values;
  -o-animation: $values;
  animation: $values;
}

@mixin animation-delay($values) {
  -webkit-animation-delay: $values;
  -moz-animation-delay: $values;
  -o-animation-delay: $values;
  animation-delay: $values;
}

@mixin transform($property) {
  -webkit-transform: $property;
  -moz-transform: $property;
  -o-transform: $property;
  -ms-transform: $property;
  transform: $property;
}

@mixin transform-style($property) {
  -webkit-transform-style: $property;
  -moz-transform-style: $property;
  -o-transform-style: $property;
  -ms-transform-style: $property;
  transform-style: $property;
}

@mixin transition($property...) {
  -webkit-transition: $property;
  -moz-transition: $property;
  -o-transition: $property;
  transition: $property;
}

@mixin rotate($degrees) {
  -webkit-transform: rotate($degrees);
  -moz-transform: rotate($degrees);
  -ms-transform: rotate($degrees);
  -o-transform: rotate($degrees);
  transform: rotate($degrees);
}

@mixin perspective($property) {
  -webkit-perspective: $property;
  -moz-perspective: $property;
  -o-perspective: $property;
  -ms-perspective: $property;
  perspective: $property;
}

@mixin backface-visibility($property) {
  -webkit-backface-visibility: $property;
  -moz-backface-visibility: $property;
  -o-backface-visibility: $property;
  backface-visibility: $property;
}

@mixin flexbox {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin align-items($align) {
  -webkit-align-items: $align;
  -moz-align-items: $align;
  -ms-align-items: $align;
  align-items: $align;
}

@mixin align-self($align) {
  -webkit-align-self: $align;
  -moz-align-self: $align;
  -ms-align-self: $align;
  align-self: $align;
}

@mixin justify-content($justify) {
  -webkit-justify-content: $justify;
  -moz-justify-content: $justify;
  -ms-justify-content: $justify;
  justify-content: $justify;
  -ms-flex-pack: $justify;
}

@mixin flex-direction($direction) {
  -webkit-flex-direction: $direction;
  -moz-flex-direction: $direction;
  -ms-flex-direction: $direction;
  flex-direction: $direction;
}

@mixin flex-wrap($wrap) {
  -webkit-flex-wrap: $wrap;
  -moz-flex-wrap: $wrap;
  -ms-flex-wrap: $wrap;
  flex-wrap: $wrap;
}

@mixin order($val) {
  -webkit-box-ordinal-group: $val;
  -moz-box-ordinal-group: $val;
  -ms-flex-order: $val;
  -webkit-order: $val;
  order: $val;
}

@mixin flex-grow($grow) {
  -webkit-flex-grow: $grow;
  -moz-flex-grow: $grow;
  -ms-flex-grow: $grow;
  flex-grow: $grow;
}

@mixin flex-shrink($shrink) {
  -webkit-flex-shrink: $shrink;
  -moz-flex-shrink: $shrink;
  -ms-flex-shrink: $shrink;
  flex-shrink: $shrink;
}

@mixin flex-basis($basis) {
  -webkit-flex-basis: $basis;
  -moz-flex-basis: $basis;
  -ms-flex-basis: $basis;
  flex-basis: $basis;
}

@mixin flex($values) {
  -webkit-box-flex: $values;
  -moz-box-flex: $values;
  -webkit-flex: $values;
  -ms-flex: $values;
  flex: $values;
}

@mixin user-select($values) {
  -webkit-user-select: $values;
  -moz-user-select: $values;
  -ms-user-select: $values;
  user-select: $values;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes loading-dots {
  0%,
  80%,
  100% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes animated-underline {
  0% {
    text-decoration-line: underline;
    text-decoration-color: transparent;
    text-underline-offset: 0;
  }

  to {
    text-decoration-line: underline;
    text-decoration-color: inherit;
    text-underline-offset: 2px;
  }
}

@keyframes slidein {
  from {
    transform: translate(100%);
    @include transition(transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms);
  }

  to {
    transform: none;
    @include transition(transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms);
  }
}

@keyframes slideout {
  from {
    transform: none;
    @include transition(transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms);
  }

  to {
    transform: translate(100%);
    @include transition(transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms);
  }
}

.home {
  .content {
    margin-top: 8.5rem;
  }

  .title,
  .name {
    padding: 4rem 0 0;

    &::before {
      display: none;
    }
  }

  .bestsellers {
    padding-bottom: 8rem;
  }

  .category-section {
    padding-top: 2rem;

    .category-article-list {
      padding-top: 4rem;
    }
  }

  & + footer {
    border: 0;
  }

  @media screen and (max-width: 820px) {
    .benefits {
      margin-top: 4rem;
    }

    // .search-result {
    //   margin-top: 4rem;
    // }
  }
}

.bestsellers {
  .category-products-list {
    .products-slider {
      width: 100%;
    }
  }

  .products-wrapper {
    .products-list {
      height: 34vw;
    }
  }

  @media screen and (max-width: 1280px) {
    // .product-block .product-img-block-wrapper {
    //   height: 14vw;
    // }

    .products-wrapper {
      .products-list {
        height: 34vw;
      }
    }
  }
}

.search-result {
  margin-bottom: 24px;

  .search-products-list {
    padding-bottom: 8px;
    .products-slider {
      width: 100%;
    }
  }
  .products-wrapper {
    .products-list {
    }
  }
}

.category-products-list {
  @include flex-wrap(wrap);
  @include flexbox;
  @include justify-content(flex-start);

  .embla-container {
    margin-right: -1.15%;
    margin-left: -1.15%;

    @media screen and (max-width: 820px) {
      margin-right: 0;
      margin-left: 0;
    }
  }

  .embla-slide {
    margin-right: 1.15%;
    margin-left: 1.15%;
    min-width: 0;

    &-m {
      @include flex(0 0 31%);
    }

    &-l {
      margin-left: 0;
      margin-right: 2rem;

      @include flex(0 0 40vw);
    }

    @media screen and (max-width: 500px) {
      @include flex(0 0 70vw);
    }
  }

  .embla-viewport {
    @media screen and (max-width: 1220px) {
      width: calc(100vw - 8rem);
    }

    @media screen and (max-width: 820px) {
      width: calc(100vw - 2rem);
    }
  }
}

.search-result {
  .search-products-list {
    @include flex-wrap(wrap);
    @include flexbox;
    @include justify-content(flex-start);

    .embla-container {
      margin-right: -1.15%;
      margin-left: -1.15%;

      @media screen and (max-width: 820px) {
        margin-right: 0;
        margin-left: 0;
      }
    }

    .embla-slide {
      margin-right: 1.15%;
      margin-left: 1.15%;
      min-width: 0;

      &-m {
        @include flex(0 0 31%);
      }

      &-l {
        margin-left: 0;
        margin-right: 2rem;

        @include flex(0 0 40vw);
      }

      @media screen and (max-width: 500px) {
        @include flex(0 0 70vw);
      }
    }

    .embla-viewport {
      @media screen and (max-width: 1220px) {
        width: calc(100vw - 8rem);
      }

      @media screen and (max-width: 820px) {
        width: calc(100vw - 2rem);
      }
    }
  }
}

.join-block {
  background-color: $color-light-bg;
  border-radius: 0.5rem;
  margin: 2rem auto;
  padding: 4rem 4rem 5rem;
  text-align: center;
  max-width: 130rem;

  .container {
    max-width: 75rem;
  }

  h3 {
    letter-spacing: -0.025em;
    font-size: 3.2rem;
    padding-bottom: 1.5rem;
  }

  p {
    max-width: 65rem;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.8rem;
  }

  form {
    @include flexbox;
    @include align-items(center);

    input {
      margin: 0 0.5rem;
      height: 4.6rem;
      border-color: #fff;

      &:focus {
        border-color: $color-accent;
      }
    }

    button {
      margin: 0 0.5rem;
      padding: 1em 1.5em;
      height: 4.5rem;
    }


  }

  @media screen and (max-width: 820px) {
    padding: 4rem 0;

    h3 {
      font-size: 2.6rem;
    }

    p {
      font-size: 1.6rem;
    }

    form {
      @include flex-direction(column);

      input,
      button {
        margin: 0 0 1rem 0;
      }
    }
  }
}
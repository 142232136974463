$form-width: 650px;
$mobile-form-width: 380px;
$error-color: red;

.return-page {
  text-align: center;

  .radio-block {
    height: 4vh;
  }

  @media screen and (max-width: 820px) {
    padding-left: 2rem;
    padding-right: 2rem;

    .full-width {
      position: relative;
      margin-left: -2rem;
      width: calc(100% + 4rem);
    }
  }

  h3 {
    padding-bottom: 0; //TODO: WTF?
    color: $color-accent;
  }

  .benefits-wrapper {
    background-color: #ffffff;
    background: linear-gradient(
      47deg,
      rgba(17, 141, 79, 0.13071165966386555) 0%,
      rgba(90, 172, 28, 0.1279105392156863) 100%
    );
    padding: 6rem 0;

    @media screen and (max-width: 820px) {
      padding: 3rem 0 4rem;
    }

    @media screen and (max-width: 380px) {
      padding-top: 1rem;
    }

    .container {
      position: relative;
    }
  }

  .benefits-wrapper-img {
    margin: 0 auto;
    position: relative;
    width: 25rem;

    @include flex(0 0 25rem);

    @media screen and (max-width: 1120px) {
      width: 20rem;

      @include flex(0 0 20rem);
    }

    @media screen and (max-width: 820px) {
      @include flex(none);
    }

    @media screen and (max-width: 380px) {
      max-width: 50vw;
    }

    img {
      height: auto;
      max-height: none;
      width: 100%;
    }
  }

  .info-block {
    position: relative;
    width: 100%;

    @include align-items(center);
    @include flexbox;
    @include justify-content(space-between);

    p {
      font-size: 1.8rem;
      line-height: 1.3;
    }

    .benefits-wrapper-text {
      text-align: left;
      margin-right: 5%;
    }

    h2 {
      color: $color-accent;
      font-size: 3.6rem;
      font-weight: 800;
      letter-spacing: -0.1rem;
    }

    @media screen and (max-width: 1520px) {
      @include justify-content(flex-start);
    }

    @media screen and (max-width: 820px) {
      @include flex-direction(column);

      &.benefits-wrapper-text {
        margin-bottom: 5rem;
        margin-right: 0;
      }
    }
  }

  .return-info-block {
    text-align: left;
  }

  .error-text {
    color: $error-color;
    display: block;
    font-size: 1.1rem;

    text-align: center;
  }

  .input-and-error {
    position: relative;

    .input-error {
      border: 1px solid $error-color;
    }
  }

  .section-title {
    padding-top: 3.5rem;
    font-weight: 400;
    color: #334;
    opacity: 0.5;
  }

  h3 + div hr {
    display: none;
  }

  .group-label {
    text-align: left;

    span {
      font-weight: bold;
    }
  }

  .on-submitted {
    max-width: $form-width;
    margin: 0 auto;

    .list {
      max-width: $form-width;
      margin: 0 auto;

      h4 {
        text-align: left;
        color: black;
      }

      li {
        text-align: left;
        padding-left: 1rem;
      }
    }

    .bg {
      // max-width: $form-width + 300px;
      padding: 2rem;
      border-radius: 0.3rem;
      margin-top: 2rem;
      background-color: #ffffff;
      background: linear-gradient(
        47deg,
        rgba(17, 141, 79, 0.08071165966386555) 0%,
        rgba(90, 172, 28, 0.0779105392156863) 100%
      );

      h3 {
        font-size: 1.7rem;
      }
    }
  }

  // .split:first-child {
  //   padding: 0 10px 0 0;
  // }

  // .split:last-child {
  //   padding: 0 0 0 10px;
  // }

  form {
    div {
      margin-top: 0.9rem;
    }

    .split {
      margin-top: 0;

      div {
        margin-top: 0;
      }

      select {
        margin-top: 0.5rem;
      }

      @media screen and (max-width: 820px) {
        width: 100%;

        & > div,
        select {
          width: 100% !important;
          min-width: 100% !important;
        }
      }
    }

    .shipping-state-zip {
      @include flex-wrap(wrap);

      @media screen and (max-width: 820px) {
        width: 100%;
      }
    }

    .group-label + div {
      margin-top: 0;

      @include flex-wrap(wrap);

      @media screen and (max-width: 820px) {
        width: 100%;
      }
    }

    @media screen and (max-width: 800px) {
      max-width: $mobile-form-width;
    }

    max-width: $form-width;
    margin: 3rem auto;
    display: flex;
    flex-direction: column;

    input[type='date']::-webkit-calendar-picker-indicator {
      margin-right: 12px;
    }

    select {
      display: block;
      width: 100%;
      font-family: inherit;
      appearance: none;
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: right 1rem center;
      background-size: 1.3em;

      @media screen and (max-width: 629px) {
        min-width: 202px;
      }

      @media screen and (max-width: 390px) {
        min-width: 162px;
      }

      min-width: 315px;
      max-width: 100%;
    }

    input[type='checkbox'] {
      width: 2rem;
      height: 2rem;
      border-color: #e6e6e6;

      @include flex(0 0 4rem);
    }

    hr {
      margin-top: 2rem;
      opacity: 0.2;
    }

    .sumbit-wrapper {
      button {
        width: 30%;
      }

      text-align: right;
    }

    input[type='text'],
    input[type='date'],
    input[type='tel'],
    input[type='email'] {
      @media screen and (max-width: 629px) {
        min-width: 175px;
      }

      min-width: 315px;
      width: 100%;
    }

    #quantity {
      @media screen and (max-width: 629px) {
        min-width: 100px;
      }

      @media screen and (max-width: 390px) {
        min-width: 60px;
      }

      min-width: 220px;
      width: 100%;
    }

    #productDetails {
      @media screen and (max-width: 629px) {
        min-width: 100px;
      }

      @media screen and (max-width: 390px) {
        min-width: 60px;
      }

      min-width: 400px;
      width: 100%;
    }

    .shipping {
      div {
        margin: 1rem 0;
      }
    }

    .checkbox-line {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      margin-top: 0;
      margin-bottom: 2rem;

      label {
        text-align: left;
        flex: 1;
        margin: 0;
      }

      .error-text {
        width: 100%;
        margin: 0;
      }
    }

    .shipping-state-zip {
      .error-text {
        position: absolute;
      }

      div {
        position: relative;
      }

      display: flex;
      flex-direction: row;
      justify-content: space-between;

      align-items: center;

      #zip {
        @media screen and (max-width: 629px) {
          min-width: 124px;
          max-width: 162px;
        }

        @media screen and (max-width: 390px) {
          min-width: 80px;
          max-width: 142px;
        }

        min-width: 315px;
      }
    }

    //TODO: All radio classes should be in another file + radioComponent.

    .reasons-wrapper {
      margin-top: 2rem;
      margin-bottom: 2rem;

      .reasons-wrapper {
        margin-top: 0;
      }

      @media screen and (max-width: 820px) {
        margin-bottom: 0;

        .radio-block:last-child {
          flex-wrap: wrap;
          height: auto;

          div {
            width: 100%;
          }

          .reasons-wrapper {
            width: 100%;
            margin: 0;
          }
        }
      }
    }
  }

  .flex {
    display: flex;
  }

  .return-display {
    padding: 1rem 4rem 4rem;
    display: flex;
    flex-direction: column;
    text-align: left;

    @media screen and (max-width: 600px) {
      padding: 1rem 2rem 2rem;
    }

    p {
      margin: 0;
      font-size: 1.6rem;
    }

    h2 {
      color: $color-primary;
      padding-right: 2rem;

      @media screen and (max-width: 600px) {
        font-size: 5.5vw;
      }
    }

    h3 {
      text-align: center;
      padding: 3rem 0 1rem;
      font-size: 1.8rem;
    }

    .return-display-header {
      margin: 0;

      @include flexbox;
      @include justify-content(space-between);

      img {
        @media screen and (max-width: 400px) {
          max-width: 20vw;
        }
      }
    }

    .return-group-wrapper {
      margin: 0rem 0 1rem;

      @include flexbox;
      @include justify-content(space-between);
      @include flex-wrap(wrap);

      .return-group2 {
        margin-bottom: 2rem;
      }
      .return-group {
        width: 100%;
        margin-bottom: 0.5rem;

        @include flexbox;

        b {
          margin-right: 1rem;
          @include flex(0 0 14rem);
        }

        @media screen and (max-width: 600px) {
          display: block;

          b {
            @include flex(0 0 auto);
            width: 100%;
          }
        }
      }
    }

    .return-table {
      margin: 0;

      th {
        border-bottom: 0;
      }

      th,
      td {
        font-size: 1.6rem;
        word-break: break-word;
        border: 1px solid #e6e6e6;
      }
    }

    .notes {
      margin-top: 3rem;

      p {
        font-size: 1.6rem;
      }

      div {
        margin: 0 0 2rem;
      }
    }
  }

  .return-link {
    text-decoration: underline;
    font-weight: bold;
    margin-bottom: 4rem;
    display: inline-block;

    &:hover {
      text-decoration: none;
    }
  }

  .printer-btn {
    margin-top: 0;
    border-radius: 10rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 10rem !important;
    margin-bottom: 3rem;
    padding: 1rem;
    display: inline-flex;
    width: 10rem;
    color: #ffffff;
    background: linear-gradient(to right, $color-secondary, $color-accent-light, $color-secondary);
    background-size: 300% 100%;
    cursor: pointer;

    &:focus,
    &:hover {
      background-position: 100% 0;
      cursor: pointer;
      border: none;
      border-radius: 0 0 0 0;
    }

    img {
      display: block;
      max-width: 2rem;
      margin-right: 1rem;
    }
  }
}

.return-display-wrapper {
  box-shadow: 0 1rem 2.5rem rgba(14, 19, 88, 5%);
  border: 1px solid #f2f2f2;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

@media print {
  .not_for_print {
    display: none;
  }
  .return-display-wrapper {
    padding: 0 24px;
    box-shadow: 0 1rem 2.5rem rgba(14, 19, 88, 5%);

    .return-display {
      li {
        display: list-item;
        list-style: disc;
      }
    }
    .return-group {
      margin-bottom: 0.5rem;
      padding: 0 0;

      b,
      p {
        padding: 0 0;
        margin: 0 0;
      }
    }
  }
  .notes {
    margin-top: 3rem;

    p {
      font-size: 1.6rem;
    }

    padding: 0 24px;
  }
  .return-table {
    width: 100%;
    margin-top: 2rem;

    th {
      border-bottom: 0;
    }

    th,
    td {
      font-size: 1.6rem;
      word-break: break-word;
      border: 1px solid #e6e6e6;
    }
  }

  .return-display-header {
    margin: 0;

    @include flexbox;
    @include justify-content(space-between);

    img {
      @media screen and (max-width: 400px) {
        max-width: 20vw;
      }
    }
  }
  * {
    color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
}

//
.spinner-container {
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

.guarantee {
    padding: 3rem;

    @media screen and (max-width: 820px) {
        padding: 2rem;
    }

    @media screen and (max-width: 600px) {
        padding: 0 0 2rem;
    }

    .guarantee-wrapper {
        background-color: #ffffff;
        background: linear-gradient(47deg, rgba(17, 141, 79, 0.13071165966386555) 0%, rgba(90, 172, 28, 0.1279105392156863) 100%);
        padding: 6rem;
        border-radius: 1rem;

        @include flexbox;
        @include align-items(center);

        @media screen and (max-width: 1280px) {
            padding: 3rem 6rem;
        }

        @media screen and (max-width: 1000px) {
            padding: 2rem 4rem 3rem;
        }

        @media screen and (max-width: 820px) {
            padding: 2rem 2rem 3rem;

            @include flex-direction(column);
        }

        @media screen and (max-width: 600px) {
            border-radius: 0;
        }
    }

    h2 {
        color: $color-accent;
        font-size: 3.6rem;
        font-weight: 800;
        letter-spacing: -0.1rem;
    }

    .guarantee-wrapper-img {
        margin-left: 5%;
        width: 25rem;

        @include flex(0 0 25rem);

        @media screen and (max-width: 1120px) {
            width: 20rem;

            @include flex(0 0 20rem);
        }

        @media screen and (max-width: 820px) {
            margin-left: 0;

            @include flex(none);
        }

        @media screen and (max-width: 380px) {
            max-width: 50vw;
        }

        img {
            height: auto;
            max-height: none;
            width: 100%;
        }
    }
}
//TODO: BASE CLASS

.categories-nav {
  background-color: $color-secondary;
  color: #ffffff;
  position: fixed;
  top: 8.5rem;
  width: 100vw;
  z-index: 190;

  .container {
    max-width: 100%;
    padding: 0;
  }
}

.categories-nav-wrapper {
  height: 6.5rem;

  @include align-items(center);
  @include flexbox;
  @include justify-content(center);
}

.categories-nav-item {
  margin: 0 0.8rem;

  a {
    background-color: #ffffff;
    border-radius: 5rem;
    color: $color-primary;
    display: block;
    font-size: 1.3rem;
    font-weight: bold;
    letter-spacing: 0.05rem;
    padding: 1rem 1.2rem;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;

    &:hover {
      color: $color-secondary;
      text-decoration: none;

      @include animation(none);
    }

    &:focus,
    &.active {
      background-color: $color-accent;
      color: #ffffff;
      text-decoration: none;

      @include animation(none);
    }
  }
}

/* Media */

@media screen and (max-width: 1340px) {
  .categories-nav {
    padding: 0 4rem;

    .embla-buttons {
      left: 0;
      position: absolute;
      right: 0;
      top: 6.5rem;
      width: 100%;

      svg {
        width: 3rem;
        height: 3rem;
      }

      .embla-button-prev {
        left: 0;
        position: absolute;
        top: -6.2rem;
        z-index: 191;
      }

      .embla-button-next {
        position: absolute;
        right: 0;
        top: -6.2rem;
        z-index: 191;
      }
    }
  }

  .categories-nav-wrapper {
    &:after,
    &:before {
      background-image: linear-gradient(to left, hsla(40, 18%, 97%, 0), 60%, $color-secondary);
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 2rem;
      z-index: 190;
    }

    &:after {
      background-image: linear-gradient(to left, $color-secondary, 60%, hsla(40, 18%, 97%, 0));
      left: auto;
      right: 0;
      z-index: 190;
    }
  }
}

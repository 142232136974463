.stars {
    display: inline-block;
    
    #stars {
        display: none;
    }

    use {
        &:nth-child(2) {
            @include transform(translate(2rem));
        }

        &:nth-child(3) {
            @include transform(translate(4rem));
        }

        &:nth-child(4) {
            @include transform(translate(6rem));
        }

        &:nth-child(5) {
            @include transform(translate(8rem));
        }
    }

    .rating {
        display: inline-block;
        height: 2rem;
        margin: 0;
        width: 10rem;
    }
}

footer {
  border-top: 1px solid #e6e6e6;
  overflow: hidden;
  padding: 7rem 0 8rem;
  position: relative;

  .footer-wrapper {
    @include align-items(flex-start);
    @include flexbox;
    @include justify-content(space-between);
  }

  .nav {
    width: 100%;

    @include align-items(flex-start);
    @include flexbox;
    @include justify-content(flex-end);

    .nav-wrapper {
      width: 75%;

      @include flex-grow(1);
    }

    .nav-menu-list {
      @include align-items(flex-start);
      @include flexbox;

      &.justify-end {
        @include justify-content(flex-end);
      }

      .footer-column {
        padding-right: 4rem;
        width: 30%;

        &:last-child {
          width: 35%;
        }
      }
    }

    .nav-link {
      background: transparent;
      display: inline-block;
      font-size: 1.4rem;
      font-weight: 700;
      letter-spacing: 0.05rem;
      margin: 0 0 2.5rem;
      opacity: 0.8;
      position: relative;
      text-decoration: none;
      text-transform: uppercase;

      @include transition(all 0.3s);

      &.link-item {
        font-weight: 400;
        margin-bottom: 1rem;
        text-transform: none;
      }

      &:focus,
      &:hover {
        opacity: 0.8;
        text-decoration: none;
      }

      & + .submenu-wrapper {
        margin-top: -1rem;
      }
    }

    .submenu-wrapper {
      display: block;
      margin-bottom: 2rem;

      .nav-link {
        color: $color-primary;
        font-size: 1.6rem;
        font-weight: 400;
        letter-spacing: 0;
        margin-bottom: 1rem;
        opacity: 1;
        text-transform: none;

        &:hover,
        &:focus {
          opacity: 0.7;
        }
      }

      b {
        font-weight: normal;
      }

      .flex-block {
        .links-block {
          margin-bottom: 2rem;
          position: relative;

          div {
            margin-bottom: 1rem;
            margin-top: 0.5rem;
          }

          .nav-link {
            font-weight: 400;
            margin: 0;
            text-transform: none;
          }
        }
      }
    }

    .nav-submenu-link-wrapper {
      background: transparent;
    }
  }
}

.social-links {
  ul {
    @include flexbox;
    @include transition(all 0.3s);

    li {
      &:not(:last-child) {
        margin-right: 1.5rem;
      }
    }
  }

  li a,
  li button {
    background: $color-light-bg !important;
    border-radius: 3rem !important;
    display: inline-block;
    height: 3.6rem;
    padding: 0.6rem;
    width: 3.6rem;

    @include align-items(center);
    @include flexbox;
    @include justify-content(center);

    &:focus,
    &:hover {
      opacity: 0.7;
    }
  }
}

.footer-info {
  font-size: 1.4rem;
  margin: 2rem 0;

  a {
    font-weight: bold;
  }

  span {
    opacity: 0.3;
    padding: 0 1rem;
  }
}

/* Media */

@media screen and (max-width: 920px) {
  footer .nav {
    @include flex-wrap(wrap);
    @include justify-content(flex-start);

    .nav-wrapper {
      margin-top: 4rem;
      width: 100%;

      @include flex-grow(auto);
    }

    .nav-menu-list {
      @include justify-content(flex-start);
    }
  }
}

@media screen and (max-width: 820px) {
  footer {
    padding: 4rem 0;

    .nav {
      .nav-wrapper {
        margin-top: 2rem;
      }

      .nav-menu-list {
        width: 100%;

        @include flex-wrap(wrap);
        @include justify-content(flex-start);

        .nav-link {
          max-width: 80%;
        }

        .footer-column {
          width: 50%;

          &:last-child {
            margin: 2rem 0;
            width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 420px) {
  footer {
    .nav .nav-menu-list .footer-column {
      padding-right: 0;
      width: 100%;

      &:last-child {
        margin: 0 0 4rem;
        width: 100%;
      }
    }
  }
}

$container-width: 900px;

.dropdown-small {
  width: 100%;
  max-width: 50rem;
  margin: 0 auto;
  padding-top: 20px;

  .selected-title {
    flex: none !important;
  }
}

.order-block {
  .dropdown-header {
    border-radius: 0.5rem;
    padding: 1rem 2rem 1rem 1rem;
    background-position: 92% center;
  }

  .order-dropdown {
    min-width: 150px;
  }

  .dropdown-list {
    border-radius: 0.5rem;
  }

  .list-item {
    margin-bottom: 0;

    span {
      opacity: 1 !important;
      font-size: 1.5rem !important;
    }
  }
}

.testimonials-page-header {
  background: linear-gradient(47deg,
      rgba(17, 141, 79, 0.1307116597) 0%,
      rgba(90, 172, 28, 0.1279105392) 100%);
  min-height: 20rem;
  padding: 0 2rem;

  h1 {
    text-align: center;
  }

  .dropdown-container {
    .set-img-wrapper {
      margin-right: 0;
    }

    .dropdown-list {
      max-height: 20rem;
      overflow: auto;
    }

    .list-item {
      margin-bottom: 0;

      span {
        text-align: left;
        font-size: 1.6rem;
        font-weight: normal;
      }
    }
  }
}

.reviews-rows {
  .loading {
    margin: 2rem;
    text-align: center;
  }

  p {
    margin-bottom: 0;
  }

  .product-reviews {
    max-width: $container-width;
    margin: 0 auto;
    padding-bottom: 0;
  }

  .sort-header {
    @include flexbox;
    @include justify-content(space-between);
    @include align-items(center);
    @include flex-wrap(wrap);

    margin: 0 auto;
    max-width: $container-width;
    padding: 2rem 4rem;

    .sort-number {
      font-weight: 500;
      padding-right: 2rem;
      color: $color-primary;
      width: 100%;
      text-align: left;
    }

    .order-block {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  .review-btn {
    border-radius: 4px;
    letter-spacing: 0;
    width: auto;
    padding: 1em;

    a {
      animation: none;
    }
  }
}

.testimonials-page-mode {
  display: flex;
  flex-direction: column;
  border-bottom: 0.7px solid #e6e6e6;
  padding-bottom: 3rem;
  min-height: 10rem;

  &:last-child {
    border-bottom: none;
  }

  .header {
    display: flex;
    padding-top: 2.5rem;

    .name {
      font-weight: bold;
      min-width: 35%;
      text-align: left;
      padding-right: 2rem;
    }

    .stars {
      margin-right: 3rem;
    }

    .date {
      opacity: 0.5;
      font-size: 1.6rem;
    }
  }

  .details {
    display: flex;
    padding-top: 1rem;

    .testimonial-product-name {
      min-width: 35%;
      font-weight: 400;
      opacity: 0.5;
      text-align: left;
      padding-right: 2rem;
    }

    .improvements {
      text-align: left;
      width: 100%;
    }
  }
}

.reviews-product-block-wrapper {
  background: #faf9fa;
  margin-bottom: 2rem;
}

.reviews-product-block {
  padding: 0;
  text-align: left;
  position: relative;

  @include flex-direction(row);
  @include flexbox;

  margin: 0 auto;
  max-width: 900px;
  padding: 3rem 4rem;

  .product-img-block {
    max-width: 25rem;
    min-height: 35rem;

    .badges-block {
      right: 1.5rem;
      left: auto;

      .product-badge {
        float: right;
      }
    }

    a {
      margin-bottom: 0;
      width: auto;
      height: 100%;
      max-height: 100%;

      @include flexbox;
      @include align-items(center);
      @include justify-content(center);
    }

    img {
      margin-bottom: 0;
      width: auto;
      height: 100%;
      max-height: 100%;
      display: block;
    }
  }

  .product-details {
    padding-left: 2rem;

    h3 {
      padding-bottom: 1rem;
      color: $color-primary;

      a {
        color: $color-primary;
      }
    }

    h3,
    p {
      text-align: left !important;
    }

    .product-price {
      padding-top: 0;
      color: $color-primary;
      font-size: 2rem;
    }

    p {
      max-width: 70rem;
      margin-bottom: 2rem;
      min-height: auto;
      max-height: none;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 992px) {
  .testimonials-page-header {
    min-height: 22rem;

    .dropdown-container {
      .dropdown-header {
        border-radius: 6rem;

        .set-img-wrapper {
          height: 5rem;
        }

        .selected-title {
          text-align: left;
          font-size: 1.8rem;
          font-weight: normal;
        }
      }

      .set-img-wrapper {
        margin-right: 1rem;
      }
    }
  }

  .dropdown-small {
    max-width: 65rem;
  }
}

@media screen and (max-width: 820px) {
  .reviews-rows .sort-header {
    padding: 2rem;
  }

  .reviews-product-block {
    .product-img-block {
      max-width: 20rem;
      min-height: 30rem;
    }

    .product-details p {
      max-width: 60rem;
      font-size: 1.7rem;
    }
  }
}

@media screen and (max-width: 600px) {
  .testimonials-page-header {
    min-height: 18rem;

    h1 {
      font-size: 3.2rem;
    }
  }

  .reviews-rows .sort-header {
    padding-bottom: 0;
  }

  .testimonials-page-mode {
    .header {
      @include flex-direction(column);
      @include align-items(flex-start);

      .stars {
        margin: 1rem 2rem;
      }
    }

    .details {
      flex-direction: column-reverse;
    }

    .testimonial-product-name {
      padding-top: 2rem;
    }
  }

  .reviews-product-block {
    .product-img-block {
      max-width: 15rem;
      min-height: 25rem;
    }

    .product-details p {
      font-size: 1.6rem;
    }
  }
}

@media screen and (max-width: 580px) {
  .reviews-product-block-wrapper {
    margin-bottom: 0;
  }

  .reviews-product-block {
    .product-img-block {
      max-width: 10rem;
      min-height: 20rem;

      a {
        height: 100% !important;
      }

      img {
        max-height: 100% !important;
        width: auto !important;
      }
    }

    .product-details {
      padding-left: 4rem;

      p {
        font-size: 1.5rem;
        max-height: 7rem;
        min-height: 7rem;
        line-height: 2.2rem;
        margin-bottom: 1rem;
      }
    }
  }
}

@media screen and (max-width: 380px) {
  .reviews-rows .sort-header {
    flex-direction: column;
    padding-bottom: 0;
    align-items: flex-start;

    p {
      margin: 1rem 0;
    }
  }
}
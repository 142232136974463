.product-icons-list {
  margin: 3.5rem 0;
  width: 100%;
  padding-left: 9rem;

  @include align-items(center);
  @include flexbox;
  @include justify-content(center);

  @media screen and (max-width: 1000px) {
    padding-left: 0;
    display: none;
    height: 0;
    overflow: hidden;
  }

  .icon-image {
    margin: 0 2rem;
    height: 5rem;
    width: 5rem;

    & + label {
      font-size: 1.6rem;
      text-transform: capitalize;
      margin: 0.5rem 2rem;
    }
  }

  .product-icon {
    text-align: center;
  }

  @media screen and (max-width: 500px) {
    margin-bottom: 1rem;

    @include align-items(flex-start);
    @include flex-direction(column);

    span {
      margin-bottom: 2rem;
      display: block;
      text-align: left;
    }
  }
}
.allergens-table {
  width: 100%;
  position: relative;
  border-top: 1px solid #1e534d;
  border-left: 1px solid #1e534d;

  @include flexbox;
  @include flex-wrap(wrap);

  div {
    text-align: center;
  }

  .allergens-table-item {
    width: calc(100% / 6);
    position: relative;

    @include flexbox;
    @include flex-direction(column);

    @media screen and (max-width: 600px) {
      width: calc(100% / 4);
    }

    div:first-child {
      background-color: #d2dddb;
      border-top: 0;

      @include flex(1);
    }

    div:last-child {
      height: 4rem;
      border-top: 0;

      svg {
        margin-top: 0.3rem;
      }
    }

    div {
      padding: 1rem;
      border: 1px solid #1e534d;
      border-left: 0;
    }
  }

  .small-close-icon {
    width: 1.4rem;
    height: 1.4rem;
  }

  & + p {
    margin-top: 2rem;
    line-height: 1.2;
  }
}

.embla {
  position: relative;
}

.embla-viewport {
  overflow: hidden;
  width: 100%;
}

.embla-viewport.is-draggable {
  cursor: grab;
  cursor: move;
}

.embla-viewport.is-dragging {
  cursor: grabbing;
}

.embla-container {
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  user-select: none;

  @include flexbox;
}

.embla-slide {
  position: relative;
}

.embla-slide-inner {
  overflow: hidden;
  position: relative;
}

.embla-button {
  cursor: pointer;
  height: 4rem;
  padding: 0;
  position: relative;
  touch-action: manipulation;
  width: 4rem;
  opacity: 0.8;

  &:focus,
  &:hover {
    opacity: 1;
  }
}

.embla-button:disabled {
  cursor: default;
  opacity: 0.3;
}

.embla-button-svg {
  height: 100%;
  width: 100%;
}

.embla-dots {
  list-style: none;
  min-height: 50px;
  padding: 1rem 0 3rem;
  margin: 0 4rem;
  width: calc(100vw - 8rem);

  @include flexbox;
  @include flex-wrap(wrap);
  @include justify-content(center);
}

.embla-dot {
  background-color: transparent;
  // cursor: pointer;
  position: relative;
  padding: 0;
  outline: 0;
  width: 0.5rem;
  height: 0.5rem;
  border: 0;
  margin: 0.2rem;

  @include flexbox;
  @include align-items(center);
  @include justify-content(center);

  &:hover,
  &:focus {
    background-color: transparent;
  }
}

.embla-dot:after {
  background-color: $color-secondary;
  opacity: 0.2;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 1rem;
  content: '';
}

.embla-dot-l:after {
  background-color: $color-secondary;
  opacity: 0.2;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 1rem;
  content: '';
}

.embla-dot.selected:after {
  background-color: $color-secondary;
  opacity: 1;
}

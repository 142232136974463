.ingredients-list {
  @include flex-wrap(wrap);
  @include flexbox;
  @include justify-content(flex-start);

  .embla-slide {
    margin-right: 2rem;
    min-width: 0;

    @include flex(0 0 40vw);

    @media screen and (max-width: 500px) {
      @include flex(0 0 70vw);
    }
  }

  .embla-viewport {
    width: calc(100vw - 2rem);
  }

  .ingredient-text-wrapper {
    min-height: 11vh;
  }
}

.product-key-ingredients {
  background-color: #fff;
  background: linear-gradient(47deg,
      rgba(17, 141, 79, 0.13071165966386555) 0%,
      rgba(90, 172, 28, 0.1279105392156863) 100%);
  padding: 4rem 0 6rem;
  position: relative;
  scroll-margin-top: 8rem;

  .container {
    @include flexbox;
    @include flex-direction(column);
  }

  h2 {
    color: $color-primary;
  }

  .title-link {
    color: $color-secondary;
    cursor: pointer;
    display: inline-block;
    top: auto;

    @include align-self(center);
  }

  .ingredient-wrapper {
    @include flex-wrap(wrap);
    @include flexbox;
    @include justify-content(space-between);
  }

  .product-ingredient {
    background-color: #ffffff;
    border-bottom-right-radius: 5rem;
    border-top-left-radius: 5rem;
    margin-bottom: 3rem;
    min-height: 15.5rem;
    padding: 1.5rem;
    width: 48%;

    @include align-items(center);
    @include flexbox;
    @include justify-content(space-between);
    @include transition(all 0.3s);

    &:hover {
      border-bottom-left-radius: 5rem;
      border-bottom-right-radius: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 5rem;
      cursor: pointer;
    }

    .ingredient-img-wrapper {
      overflow: hidden;
      width: 25%;
      position: relative;
      background-image: url('/images/gv-icon.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 30%;

      @include align-items(center);
      @include flex(0 0 25%);
      @include flexbox;
      @include justify-content(center);

      &:after {
        content: '';
        display: block;
        padding-bottom: 100%;
      }

      img {
        min-height: 100%;
        min-width: 100%;
        object-fit: cover;
        object-position: center center;
        will-change: transform;

        @include flex-shrink(0);
      }
    }

    .ingredient-text-wrapper {
      margin-left: 1rem;

      @include flex(1);
    }

    h4 {
      color: $color-primary;
      font-size: 1.8rem;
      opacity: 1;
    }

    p {
      margin-bottom: 0;
    }

    .more-icon {
      background-image: url('/images/icons/arrow-more.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%;
      height: 4rem;
      margin-left: 3rem;
      margin-right: 1rem;
      text-indent: -9999px;
      white-space: nowrap;
      width: 4rem;

      @include flex(0 0 4rem);

      &:hover {
        background-image: url('/images/icons/arrow-more-active.svg');
      }
    }
  }

  @media screen and (max-width: 800px) {
    padding: 2rem 0 6rem;

    h2 {
      text-align: center;
    }

    .product-ingredient {
      border-bottom-right-radius: 2rem;
      border-top-left-radius: 2rem;
      margin-bottom: 0;
      height: 100%;
      padding: 2rem 2rem 8rem;
      text-align: center;
      width: 100%;

      @include flex-direction(column);

      &:hover {
        border-bottom-left-radius: 2rem;
        border-bottom-right-radius: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 2rem;
      }

      .ingredient-img-wrapper {
        margin-bottom: 2rem;
        margin-right: 0;
        max-width: 15rem;
        width: 50%;

        @include flex(none);
      }

      .more-icon {
        bottom: 2rem;
        position: absolute;
        right: 1rem;
      }
    }

    .embla-container {
      margin-left: -2rem;
    }

    .embla-dots {
      width: calc(100vw - 12rem);
    }
  }

  @media screen and (max-width: 600px) {
    .embla-slide {
      @include flex(0 0 70vw);
    }
  }
}

.ingredient-modal {
  background: #fff;
  max-width: 50vw;
  padding: 2rem 3rem;

  .ingredient-title-wrapper {
    margin-bottom: 1rem;

    @include align-items(center);
    @include flexbox;
  }

  .ingredient-img-wrapper {
    margin-right: 1.5rem;
    overflow: hidden;
    position: relative;
    width: 15rem;
    background-image: url('/images/gv-icon.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 30%;

    @include align-items(center);
    @include flex(0 0 15rem);
    @include flexbox;
    @include justify-content(center);

    &:after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }

    img {
      min-height: 100%;
      min-width: 100%;
      object-fit: cover;
      object-position: center center;
      will-change: transform;

      @include flex-shrink(0);
    }
  }

  h2 {
    color: $color-primary;
    padding-bottom: 1rem;
    text-wrap: pretty;
  }

  h4 {
    color: $color-primary;
    font-size: 1.6rem;
    margin-top: 2rem;
    opacity: 1;
  }

  p {
    margin-bottom: 0;
  }

  .ingredient-text-wrapper {
    margin: 2rem 0 3rem;

    h3 {
      margin: 2rem 0 1rem;
      padding: 0;
      font-size: 2rem;
      color: $color-primary;
    }

    ul,
    ol {
      list-style: disc;
      margin-left: 2rem;

      li {
        margin-bottom: 0.3rem;
        line-height: 2.2rem;
      }
    }
  }

  @media screen and (max-width: 1200px) {
    max-width: 66rem;
  }

  @media screen and (max-width: 800px) {
    .ingredient-title-wrapper {
      text-align: center;

      @include flex-direction(column);
      @include justify-content(flex-start);
    }

    .ingredient-img-wrapper {
      @include flex(none);
    }
  }

  @media screen and (max-width: 700px) {
    max-width: none;

    .ingredient-text-wrapper {
      margin: 2rem 0 3rem;
    }
  }
}
.categories {
  position: relative;

  .content {
    margin-top: 15rem;
  }

  .title-link {
    display: none;
  }
}

.category-section {
  border-top: 1px solid #e6e6e6;

  .subtitle {
    color: #696f68;
    font-size: 1.8rem;
    margin-top: -3rem;
    padding-bottom: 3rem;
    position: relative;
  }

  p.bigger {
    position: relative;
    margin-top: -4rem;
    font-size: 3rem;
    opacity: 0.8;
    line-height: 1.1;
    color: #282c2f;
  }

  p.smaller {
    opacity: 0.8;
    color: #282c2f;
    font-size: 1.8rem;
    margin-bottom: 4rem;
  }

  h3.subcategories {
    font-size: 2.4rem;
    margin-top: 5rem;

    & + .category-article-wrapper {
      margin-top: 2rem;
    }
  }

  h3.title {
    font-size: 3rem;
    padding-top: 5rem;
    border-top: 1px solid #e6e6e6;
    scroll-margin-top: 15rem;
    padding-bottom: 2rem;
  }
}

.subcategories-nav {
  margin-left: -0.8rem;

  @include justify-content(flex-start);
  @include flexbox;
  @include flex-wrap(wrap);


  &+h3.title {
    border: 0;
  }


  .subcategories-nav-item {
    margin: 0.75rem 0.8rem;
  }

  .subcategories-nav-link {
    background-color: #e5f4e1;
    border-radius: 5rem;
    color: $color-primary;
    display: block;
    font-size: 1.4rem;
    font-weight: bold;
    letter-spacing: 0.05rem;
    padding: 1rem 1.2rem;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;

    &:hover {
      color: $color-secondary;
      text-decoration: none;

      @include animation(none);
    }

    &:focus,
    &.active {
      background-color: $color-secondary;
      color: #ffffff;
      text-decoration: none;

      @include animation(none);
    }
  }
}

.category-article-list {
  @include justify-content(flex-start);
  @include flexbox;
  @include flex-wrap(wrap);

  .embla-slide {
    margin-right: 2rem;
    min-width: 0;

    @include flex(0 0 70vw);
  }

  .embla-viewport {
    width: calc(100vw - 2rem);
  }

  @media screen and (min-width: 1280px) {
    padding: 0 4rem !important;
  }
}

.category-article-list2 {
  .embla-dots {
    width: calc(100vw - 12rem);

    @media screen and (min-width: 820px) {
      width: calc(100vw - 16rem);
    }

    @media screen and (min-width: 1220px) {
      width: calc(100% - 8rem);
    }
  }

  .articles-slider {
    margin-top: -5rem;

    @media screen and (max-width: 1100px) {
      margin-top: 0;
    }
  }

  .embla-container {
    margin-right: -2.5%;
    margin-left: -2.5%;
    padding: 5rem;

    @media screen and (max-width: 1100px) {
      padding: 0;
    }

    @media screen and (max-width: 820px) {
      margin-right: 0;
      margin-left: 0;
    }
  }

  .embla-slide {
    margin-right: 2.5%;
    margin-left: 2.5%;
    min-width: 0;

    &-m {
      @include flex(0 0 calc(85%/3));
    }

    &-l {
      margin-left: 0;
      margin-right: 2rem;

      @include flex(0 0 70vw);
    }

    @media screen and (min-width: 1101px) {
      &:last-child {
        margin-right: 8rem;
      }
    }
  }

  .embla-viewport {
    @media screen and (max-width: 1220px) {
      width: calc(100vw - 8rem);
    }

    @media screen and (max-width: 820px) {
      width: calc(100vw - 2rem);
    }
  }

  .category-article {
    width: 100%;
    margin-right: 0;

    @media screen and (max-width: 1100px) {
      &:hover:before {
        display: none;
      }
    }
  }
}

.category-article {
  margin-bottom: 6rem;
  margin-right: 5%;
  position: relative;
  width: 30%;

  &:nth-child(3n) {
    margin-right: 0;
  }

  @include flexbox;
  @include flex-direction(column);

  &:hover {
    &::before {
      background-color: #fff;
      box-shadow: 0 1rem 4rem 0 rgba(0, 0, 0, 0.2);
      content: '';
      display: block;
      height: calc(100% + 4rem);
      left: -5%;
      opacity: 1;
      pointer-events: none;
      position: absolute;
      top: -2rem;
      width: calc(100% + 10%);

      @include transition(opacity 0.3s);
    }
  }

  &.top:hover {
    &::before {
      content: '';
      left: -2rem;
      width: calc(100% + 4rem);
    }
  }

  .category-article-text {
    max-width: 100%;
    position: relative;

    h3 {
      color: $color-secondary;
    }
  }

  .category-article-img {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 5rem;
    font-size: 0;
    height: 23rem;
    letter-spacing: 0;
    margin-bottom: 3rem;
    max-width: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
    word-spacing: 0;
    z-index: 50;

    a {
      height: 100%;

      @include flexbox;
      @include align-items(center);
      @include justify-content(center);

      img {
        flex-shrink: 0;
        min-height: 100%;
        min-width: 100%;
        width: auto;
        object-fit: cover;
        object-position: center center;
        will-change: transform;

        @include transition(transform 0.45s ease-in-out, opacity 0.3s linear);
        @include transform(scale(1) translateZ(0));
      }

      &:hover img {
        @include transition(transform 0.6s ease-out, opacity 0.3s !important);
        @include transform(scale(1.1) translateZ(0));
      }
    }
  }
}

.category-title {
  @include align-items(center);
  @include flexbox;
  @include justify-content(space-between);

  h2.title {
    font-size: 4rem;
  }

  &+h3.subcategories {
    margin-top: -2rem;
  }
}

.title-link {
  font-size: 1.8rem;
  font-weight: bold;
  position: relative;
  text-decoration: none;
  top: 1rem;

  @include transition(all 0.25s);

  &:after {
    background-color: $color-secondary;
    content: '';
    display: block;
    height: 0.2rem;
    position: relative;
    top: 0.3rem;
    width: 100%;

    @include transition(all 0.25s);
  }

  &:hover {
    color: $color-secondary-dark;
    text-decoration: none;

    @include animation(none);
    @include transition(all 0.25s);

    &:after {
      width: 0;

      @include transition(all 0.25s);
    }
  }

  &:focus,
  &.active,
  &:active {
    text-decoration: none;

    @include animation(none);
  }
}

@media screen and (max-width: 1280px) {
  .category-article .category-article-img {
    height: 20vw;
  }
}

@media screen and (max-width: 820px) {
  .category-article-list {
    justify-content: space-between;
  }

  .category-article {
    width: calc(50% - 1.5rem);
    margin-bottom: 2rem;
    margin-right: 0;

    &:first-child {
      width: 100%;

      .category-article-img {
        height: 50vw;
      }
    }

    &:hover {
      &::before {
        display: none;
      }
    }

    .category-article-img {
      height: 27vw;
      margin-bottom: 1.5rem;
    }
  }

  .category-section {
    .subtitle {
      margin-top: 0;
    }
  }

  .category-section {
    h3.subcategories {
      font-size: 2.4rem;
      margin-top: 4rem;
    }

    p.bigger {
      font-size: 2.4rem;
      margin-top: 0;
    }

    h3.title {
      font-size: 2.8rem;
      padding-top: 3rem;
      margin-top: 1rem;
    }
  }

  .category-title {
    margin-right: 2rem;

    h2.name,
    h2.title {
      padding-bottom: 2rem;
    }

    h2.title {
      font-size: 3rem;
    }

    &+h3.subcategories {
      margin-top: 2rem;
    }
  }

  .subcategories-nav {
    .subcategories-nav-link {
      font-size: 1.3rem;
      background-color: #e5f4e1;
    }
  }
}

@media screen and (max-width: 580px) {
  .title-link {
    font-size: 1.6rem;
  }

  .category-section {
    h3.subcategories {
      font-size: 2.2rem;
    }

    p.bigger {
      font-size: 1.8rem;
      line-height: 1.3;
    }

    p.smaller {
      font-size: 1.6rem;
    }

    h3.title {
      font-size: 2.4rem;
    }
  }

  .category-title {
    h2.title {
      font-size: 2.8rem;
    }

    h2.title,
    h2.name {
      padding-top: 4rem;
    }
  }
}

@media screen and (max-width: 480px) {
  .category-article .category-article-text {
    h4 {
      font-size: 1.3rem;
    }

    h3 {
      font-size: 1.8rem;
    }
  }

  .category-article:first-child .category-article-text h3 {
    font-size: 2rem;
  }
}

@media screen and (max-width: 360px) {
  .title-link {
    font-size: 1.4rem;
  }
}
.modal-wrapper {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 201;

  &:before {
    background: rgba(0, 0, 0, 60%);
    content: '';
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 201;
  }

  &.visible {
    display: flex !important;

    @include justify-content(center);
    @include align-items(center);
  }
}

.modal-body {
  align-self: center;
  border-bottom-right-radius: 5rem;
  border-top-left-radius: 5rem; 
  max-height: 90vh;
  max-width: 90%;
  overflow: hidden;
  position: relative;
  z-index: 201;

  .close {
    background: transparent;
    cursor: pointer;
    height: 2rem;
    padding: 0;
    position: absolute;
    right: 1.5rem;
    top: 1rem;
    width: 2rem;
    z-index: 102;

    &:after,
    &:before {
      border-top: 2px solid $color-primary;
      content: '';
      height: 100%;
      position: absolute;
      right: -0.6rem;
      top: 0.6rem;
      width: 100%;

      @include rotate(-45deg);
    }

    &:before {
      right: 0.6rem;

      @include rotate(45deg);
    }

    &:hover {
      opacity: 0.7;
    }

    & + div,
    & + main {
      overflow-y: auto;
      max-height: 90vh;
      position: relative;
    }
  }

  .modal-content {
    border-radius: 0.5rem;
    z-index: 101;
  }
}

.modal {
  background: #fff;
  padding: 5rem 4rem 4rem;
  max-width: 50rem;
  text-align: center;

  @include flexbox;
  @include flex-direction(column);
  @include justify-content(center);
  @include align-items(center);

  button {
    margin: 0;
  }
}

.contact-wrapper {
  margin: 4rem 0;

  @include flexbox;
  @include justify-content(space-between);
}

.customer-text .customer-section:last-child {
  margin-top: 4rem;
}

.contacts-page {
  .container {
    position: relative;
    text-align: left;

    .text-page-title {
      position: absolute;

      h1 {
        width: auto;
        padding-top: 0;
      }
    }
  }

  .customer-section {
    margin-top: 2rem;

    @include flexbox;

    .phone-details,
    .email-details {
      padding-left: 2rem;
    }

    div:first-child {
      @include flex(0 0 4.8rem);
    }

    img {
      width: 4.8rem;
      height: 4.8rem;
    }

    .email-details {
      p {
        margin: 0.4rem 0;
      }

      .email {
        color: black;
        font-weight: 600;
      }
    }

    .phone-details {
      p {
        margin: 0;
      }

      .small {
        font-size: 1.5rem;
      }

      .phone {
        margin-bottom: 0.5rem;
        color: $color-secondary;
        font-weight: 600;
      }
    }
  }

  .return-text {
    padding-top: 2.2rem;
  }

  .contact-text {
    margin: 5rem 0 0 0;

    .customer-text {
      margin-top: 6rem;

      @media screen and (max-width: 600px) {
        margin-top: 0;
      }

      h2 {
        color: black;
        font-weight: 400;
      }
    }

    h2 {
      color: black;
      font-weight: 300;
    }
  }

  .uppercase {
    font-weight: 300;
  }
}

.contact-form {
  max-width: 72rem;
  width: 50%;
  padding: 3.6rem;

  @media screen and (max-width: 400px) {
    padding: 2rem;
    width: 100;
  }

  .submit {
    margin: 2rem 0 0;
  }
}

.contact-text {
  width: 48rem;

  p {
    font-size: 1.8rem;
  }

  @include flex(0 0 46rem);

  a {
    word-break: break-all;
  }
}

@media screen and (max-width: 991px) {
  .contacts-page .container {
    max-width: 72rem;
    padding: 0 2rem;
  }

  .contact-wrapper {
    @include flex-direction(column);
    @include flex-wrap(wrap);
    @include justify-content(center);
  }

  .contact-form,
  .contact-text {
    margin-left: 0;
    max-width: 100%;
    width: 100%;

    br {
      display: none;
    }

    @include flex(none);
  }

  .contact-form {
    margin-top: 4rem;
  }
}

.green-text {
  color: $color-secondary;
}

.product-additional-info {
  .accordion {
    border-bottom: 1px solid #e6e6e6;
    border-top: 1px solid #e6e6e6;
    margin-bottom: 7.5rem;
  }

  .product-key-ingredients {
    background-color: #fff;
    background: linear-gradient(
      313deg,
      rgba(17, 141, 79, 0.13071165966386555) 0%,
      rgba(90, 172, 28, 0.1279105392156863) 100%
    );
    position: relative;
  }

  .sup-fact-table-2 {
    border: 1px solid #1e534d;
    margin: 0 auto 4rem;
    width: 70rem;
    max-width: 100%;

    .sup-fact-header {
      .title,
      .name {
        span {
          font-weight: 400;
        }
      }
      .columns {
        span {
          @media all and (max-width: 600px) {
            font-size: 1.4rem;
          }
          font-size: 1.6rem;
        }
      }

      .columns {
        border-top: 8px solid #1e534d;
        border-bottom: 8px solid #1e534d;
      }
      text-align: left;

      padding: 0 1rem 1rem;
      position: relative;

      h2 {
        margin-bottom: 0;
        color: $color-primary;
        padding: 1.5rem 0;
      }

      p {
        margin-bottom: 0;

        @media all and (max-width: 600px) {
          font-size: 1.5rem;
        }
      }
    }

    .sup-fact-body {
      tr {
        border-bottom: 1px solid #1e534d;
      }
      border-bottom: 7px solid #1e534d;

      .center {
        text-align: center;
      }
      span {
        @media all and (max-width: 600px) {
          font-size: 1.4rem;
        }
        font-size: 1.6rem;
        font-weight: 400;
      }
    }
    .sup-fact-footer {
      tr {
        border-bottom: 1px solid #1e534d;
      }
      span {
        font-size: 1.6rem;
        @media all and (max-width: 600px) {
          font-size: 1.4rem;
        }
        // font-weight: 400;
      }
    }
  }

  .sup-fact-table {
    border: 1px solid #1e534d;
    margin: 0 auto 4rem;
    max-width: 100%;
    text-align: left;
    width: 70rem;

    ul {
      li {
        border-bottom: 1px solid #1e534d;
        display: table;
        width: 100%;

        &.last {
          border-bottom: 8px solid #1e534d;
        }

        span {
          display: table-cell;
          padding: 1rem;
          vertical-align: top;

          @media all and (max-width: 600px) {
            font-size: 1.4rem;
          }
        }
      }

      &.sup-fact-title {
        li span {
          &:first-child {
            padding-left: 60%;
            width: calc(100% - 12rem);
          }

          &:nth-child(2) {
            text-align: center;
            width: 12rem;
          }
        }
      }

      &.sup-fact-list {
        li span {
          &:first-child {
            width: calc(100% - 21rem);
            // max-width: calc(100% - 21rem);
          }

          &:nth-child(2) {
            width: 9rem;
            padding-left: 1rem;
            text-align: center;
          }

          &:last-child {
            width: 12rem;
            padding-left: 1rem;
            text-align: center;
          }
        }
      }

      &.sup-fact-notice {
        border-top: 1px solid #1e534d;

        li:last-child {
          border: none;
        }
      }
    }

    .sup-fact-header {
      padding: 0 1rem 1rem;
      border-bottom: 8px solid #1e534d;
      position: relative;

      h2 {
        margin-bottom: 0;
        color: $color-primary;
        padding: 1.5rem 0;
      }

      p {
        margin-bottom: 0;

        @media all and (max-width: 600px) {
          font-size: 1.4rem;
        }
      }
    }
  }
}

.faqs-page {
  .product-faqs {
    z-index: auto;

    .container {
      max-width: 860px;

      @media screen and (max-width: 820px) {
        & > h2 {
          padding-top: 0;
        }
      }

      @media screen and (max-width: 600px) {
        & > h2 {
          padding-bottom: 1rem;
        }
      }
    }

    .faq-text {
      text-align: left;
    }
    
  }

  .accordion {
    margin-top: 2rem;
    background-color: #f8f8f6;
    padding: 0 3rem;
    border-radius: 1rem;

    @media screen and (max-width: 600px) {
      padding: 0 1.5rem;
    }

    button:hover .accordion-icon {
      background-color: #ffffff;
    }
  }
}
.product-main-details {
  padding: 6rem 0 3rem;

  @media screen and (max-width: 1000px) {
    padding-top: 3rem;
  }

  .container {
    @include flexbox;

    @media screen and (max-width: 1000px) {
      @include flex-direction(column);
    }

    @media screen and (min-width: 1025px) {
      .carousel-main-container {
        max-width: 520px;
      }
    }
  }

  &.digital-version {
    .product-image {
      position: relative;
      width: 40%;
      min-height: 50rem;
    }

    &.sm {
      .product-image {
        width: 100%;
      }
    }

    .digital-description {
      min-height: none !important;
      max-height: none !important;
    }
  }
}

@media screen and (min-width: 1025px) {
  .carousel-main-container {
    max-width: 520px;
  }
}

.product-functions {
  margin-left: 6rem;
  padding: 0 2rem;

  @include flex(1);

  @media screen and (max-width: 1000px) {
    margin-left: 0;
    padding: 0;
    width: 100%;
  }

  .badges-block {
    float: right;
    margin: 0.6rem 0;

    .product-badge {
      float: right;
      clear: none;
      margin-left: 0.5rem;
    }
  }

  .product-info-block {
    .product-name {
      color: #1a2f19;
      font-size: 3.8rem;
      font-weight: 800;
      padding-bottom: 1rem;
    }

    a {
      color: #1a2f19;
    }

    .product-description {
      margin-bottom: 1rem;
      text-align: left;
      min-height: auto;
    }

    .product-rate {
      color: $color-secondary;
      margin: 0 0 0.5rem;

      @include align-items(center);
      @include flexbox;
      @include justify-content(flex-start);

      .stars {
        margin-top: 0.2rem;
        margin-right: 0.5rem;
      }
    }

    .product-price {
      color: #1a2f19;
      font-size: 2.5rem;
      margin: 1rem auto 0;
      padding-bottom: 1rem;

      .old-price {
        display: inline-block;
        margin-right: 1rem;
        opacity: 0.5;
        text-decoration: line-through;
      }
    }

    .cart-btn {
      display: block;
      width: 100%;

      button {
        margin: 0;
      }
    }
  }

  .radio-wrapper {
    margin-top: 2rem;
  }

  .radio-block {
    border-radius: 1rem;
    border: 1px solid #95a8a6;
    outline: 1px solid transparent;
    margin: 0 -2rem 2rem;
    padding: 0 1.5rem 0 2rem;
    position: relative;

    @media screen and (max-width: 820px) {
      margin: 0 0 2rem;
    }

    &:has(.active) {
      border-color: $color-accent;
      outline: 1px solid $color-accent;
      background-color: #f2f6f5;
    }

    &.once {
      .original-price {
        display: none;
      }

      .option-price {
        color: #1a2f19;
      }
    }

    &:last-child {
      margin-bottom: 1rem;
    }

    &:not(:first-child) {
      .best-value {
        background-color: #dace88;
        color: #1a2f19;
      }
    }
  }

  .radio-label {
    cursor: pointer;
    display: inline-block;
    line-height: 2;
    margin-bottom: 0;
    margin-left: 0;
    padding: 1rem 0 1rem 3.5rem;
    width: 100%;

    &::before {
      background-color: transparent;
      border-radius: 10rem;
      border: 1px solid #95a8a6;
      content: '';
      display: block;
      height: 2.5rem;
      left: 2rem;
      position: absolute;
      top: 1.6rem;
      transition: all 0.3s;
      width: 2.5rem;
    }

    &::after {
      border-radius: 0.6rem;
      content: '';
      display: block;
      height: 0.9rem;
      left: 2.8rem;
      position: absolute;
      top: 2.4rem;
      transition: all 0.3s;
      width: 0.9rem;
    }

    .option-text {
      color: #1a2f19;
      font-size: 1.8rem;
    }

    .option-list {
      width: calc(100% - 13rem);

      li {
        background-image: url('/images/icons/arrow.png');
        background-position: left top;
        background-repeat: no-repeat;
        background-size: 1.6rem auto;
        padding: 0 0 0 2.5rem;
        color: #3a8457;
        line-height: 1.2;
        font-weight: normal;

        &:last-child {
          margin-bottom: 1rem;
        }
      }
    }

    .option-price {
      color: #1a2f19;
      position: absolute;
      right: 2rem;
      text-align: right;
      top: 1.3rem;
      font-size: 2.6rem;
      line-height: 1;
    }

    .original-price {
      display: inline-block;
      width: 100%;
      text-align: right;
      color: #95a8a6;
      font-weight: normal;
      font-size: 1.6rem;
      line-height: 1.3;
    }

    .per-bottle {
      display: inline-block;
      width: 100%;
      text-align: right;
      color: #95a8a6;
      font-weight: normal;
      font-size: 1.6rem;
      line-height: 1.3;
    }

    .promo {
      color: $color-accent;
      display: block;
      line-height: 1.3;
      white-space: normal;
    }
  }

  .radio-label.active::before {
    background-color: $color-accent;
    border-color: $color-accent;
  }

  .radio-label.active::after {
    background-color: #f3f6f5;
  }

  .radio-input {
    display: inline;
    left: 3rem;
    padding: 0;
    position: absolute;
    top: 0.5rem;
    vertical-align: middle;
    visibility: hidden;
    width: 1.6rem;
  }

  .best-value {
    background: $color-accent;
    border-radius: 1.2rem;
    color: #fff;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 2.2;
    margin-left: -100px;
    padding: 0 1rem;
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    top: -1.3rem;
    right: 50%;
    transform: translate(50%, 0);
  }

  .delivery-block {
    margin: 2rem auto;
    text-align: center;
    vertical-align: middle;

    &:before {
      background-image: url('/images/icons/delivery-icon.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%;
      content: '';
      display: inline-block;
      height: 2rem;
      margin-right: 1rem;
      position: relative;
      top: 0.4rem;
      width: 2rem;
    }
  }

  .product-actions {
    position: relative;

    @include flexbox;
  }

  .quantity-selector {
    @media screen and (min-width: 1000px) and (max-width: 1200px) {
      width: 100%;
    }

    @media screen and (max-width: 600px) {
      width: 100%;
    }

    .quantity-selector-btn {
      height: 5.6rem;
      width: 5.6rem;
    }

    .current-quantity {
      font-size: 2rem;
      width: 7.5rem;

      @media screen and (min-width: 1000px) and (max-width: 1200px) and (max-width: 600px) {
        width: calc(100% - 11.2rem);
      }
    }
  }

  .cart-btn {
    position: relative;

    @include flex-grow(1);

    button {
      margin: 0;
      width: 100%;
    }
  }

  .sku {
    margin: 1rem 0 2rem;
    font-size: 1.4rem;
  }

  .recommended-list {
    padding-top: 4rem;
    margin-top: 4rem;
    border-top: 1px solid #e6e6e6;

    h3 {
      font-size: 1.8rem;
      padding-bottom: 3rem;
      color: #1a2f19;
    }

    .product-block {
      padding: 0;
      border: 0;
      margin: 0;
      border-bottom: 1px solid #e6e6e6;
      padding-bottom: 2rem;
      margin-bottom: 2rem;

      &:last-child {
        border: 0;
      }

      @include flex-direction(row-reverse);
      @include align-items(center);
    }

    .product-name {
      font-size: 1.8rem;
      font-weight: bold;
      text-align: left;
      min-height: 0;
    }

    .product-price {
      float: none;
      text-align: left;
      margin: 0;
      font-size: 1.6rem;
    }

    .product-info-block {
      margin: 0 1rem;
      @include flex(1);
    }

    .product-img-block {
      @include flex(0 0 9rem);
    }

    .cart-btn {
      max-width: 14rem;
      margin: 0;
    }
  }

  @media screen and (max-width: 600px) {
    .radio-label {
      padding: 1rem 0 1rem 4rem;

      &::before {
        left: 1.5rem;
      }

      &::after {
        left: 2.3rem;
      }

      .option-price {
        font-size: 2rem;
        right: 1.5rem;
        top: 2.2rem;
      }

      .original-price {
        font-size: 1.4rem;
      }

      .option-text {
        max-width: calc(100% - 12rem);
        display: inline-block;
        line-height: 1.3;
        padding-top: 0.8rem;
      }
    }

    .radio-block {
      padding: 0 1rem;
    }
  }

  @media screen and (max-width: 500px) {
    .quantity-selector {
      margin: 0;
      width: 100%;

      @include justify-content(space-between);

      .current-quantity {
        @include flex-grow(1);
      }
    }
  }

  @media screen and (max-width: 450px) {
    .radio-label {
      .option-list {
        width: 100%;
      }
    }
  }
}

// hyex/ui/styles
// portal/styles (Article, Carousels)

/* Fonts */
// @import 'https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400;1,500&display=swap';

$font-general: 'Karla', helvetica, arial, sans-serif;
$font-secondary: 'Karla', helvetica, arial, sans-serif;

/* Colors */
$color-primary: #282c2f;
$color-secondary: #5aac1c;
$color-secondary-dark: #4e9f11;
$color-light: #e6e6e6;
$color-accent: #118d4f;
$color-accent-light: #65ab80;

/* Backgrounds */
$color-light-bg: #eef7e8;

$badge-color: #f22a4d;

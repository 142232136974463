.product-banner {
  background: linear-gradient(
    313deg,
    rgba(17, 141, 79, 0.13071165966386555) 0%,
    rgba(90, 172, 28, 0.1279105392156863) 100%
  );
  height: 60vw;
  max-height: 57rem;
  position: relative;

  @media screen and (max-width: 1319px) {
    max-height: 45rem;
  }

  @media screen and (max-width: 1000px) {
    height: auto;
    max-height: none;
  }

  &:after {
    background-color: #fff;
    bottom: 0;
    content: '';
    height: 5rem;
    left: 0;
    position: absolute;
    right: 0;
    width: 100%;
    z-index: 3;
  }

  &:before {
    background-image: url('/images/gv-icon.svg');
    background-position: 240% bottom;
    background-repeat: no-repeat;
    background-size: 80%;
    content: '';
    height: 100%;
    opacity: 0.1;
    position: absolute;
    width: 100%;
    z-index: 2;
  }

  .banner-nav {
    height: 100%;
    position: relative;
    touch-action: pan-y;
    z-index: 4;

    @media screen and (max-width: 1000px) {
      height: auto;
    }

    @include flexbox;
    @include flex-direction(column-reverse);

    .embla-viewport,
    .embla,
    .embla-container {
      height: 100%;
      width: 100%;

      @media screen and (max-width: 1000px) {
        height: auto;
      }
    }

    .banner-item-slide {
      position: relative;

      @include align-items(center);
      @include flex(0 0 100%);
      @include flexbox;
      @include justify-content(space-between);

      @media screen and (max-width: 1000px) {
        @include flex-direction(column-reverse);
        @include justify-content(flex-end);
      }
    }

    .embla-dots {
      top: 0;
      padding: 0;
      position: absolute;
      z-index: 6;

      min-height: 25px;

      @include align-items(center);

      @media screen and (max-width: 1000px) {
        top: 40vw;
      }
    }
  }

  .bg-img {
    height: calc(100% - 2rem);
    position: absolute;
    width: 100%;
    overflow: hidden;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;

    @include flexbox;
    @include justify-content(flex-end);

    img {
      display: block;
      height: 100%;
      max-height: 100%;
      margin-top: 0;
      max-width: none;
      width: auto;
      min-width: 0;
      position: absolute;
      right: 0;
      top: 0;
      left: auto;
      z-index: 1;

      @include align-self(flex-end);
    }

    @media screen and (max-width: 1700px) {
      img {
        right: calc((30vw - 60rem) / 2);
      }
    }

    @media screen and (max-width: 1319px) {
      img {
        right: calc((30vw - 50rem) / 2);
      }
    }

    @media screen and (max-width: 1000px) {
      display: block;
      height: 40vw;
      margin-left: 0;
      max-width: 100%;
      position: relative;
      width: 100%;

      img {
        height: auto;
        margin-top: 0;
        max-width: 100%;
        width: 100%;
        right: 0;
        max-height: none;
      }
    }
  }

  .product-info-block {
    margin-top: 0;
  }

  .product-block {
    height: auto;
    position: relative;
    padding-left: calc((100vw - 1200px) / 2);
    z-index: 2;

    @include align-items(center);
    @include flex(0 0 calc((100vw - 1200px) / 2 + 87rem));
    @include flex-direction(row);
    @include justify-content(space-between);

    @media screen and (max-width: 1319px) {
      padding-left: 3rem;
      padding-bottom: 2rem;

      @include flex(0 0 calc(50vw + 22rem));
    }

    @media screen and (max-width: 1000px) {
      height: auto;
      max-width: 80rem;
      padding: 2rem;
      width: 100%;

      @include flex(none);
    }

    @media screen and (max-width: 600px) {
      padding-bottom: 8rem;
      min-height: 35rem;

      @include align-items(center);
    }

    .product-badge {
      display: none;
    }

    .product-label {
      right: 10%;
      top: 0;
      width: 35%;
      height: 35%;

      @media screen and (max-width: 1000px) {
        width: 43%;
        right: 0;
      }

      @media screen and (max-width: 600px) {
        right: 10%;
      }
    }

    .product-info-block {
      height: 100%;
      max-height: 100%;

      @include flexbox;
      @include flex-direction(column);
      @include justify-content(center);
      @include flex(1);

      @media screen and (max-width: 1000px) {
        height: auto;
      }

      @media screen and (max-width: 600px) {
        margin: 2rem 0;
        max-width: calc(100% - 17rem);
        width: 100%;
      }
    }

    .product-img-block {
      background: transparent;
      bottom: -2rem;
      margin: 0;
      right: auto;
      z-index: 1;
      width: 54rem;

      @include flex(0 0 54rem);

      @media screen and (max-width: 1319px) {
        width: 43rem;
        @include flex(0 0 43rem);
      }

      @media screen and (max-width: 1000px) {
        bottom: -2rem;
        height: 40rem;
        width: 33rem;
        right: 5rem;
        @include flex(0 0 33rem);
      }

      @media screen and (max-width: 600px) {
        height: 25rem;
        margin-top: 2rem;
        position: absolute;
        right: 5rem;
        bottom: 2rem;
      }

      .product-img-block-wrapper {
        background-position: center 3rem;
        @include transition(none);
        @include transform(none);

        @media screen and (max-width: 1000px) {
          background-image: none;
        }

        a {
          @include align-items(flex-end);
          @include justify-content(center);

          @media screen and (max-width: 1000px) {
            @include justify-content(flex-end);
          }
        }

        &:hover {
          @include transition(none);
          @include transform(none);
        }

        img {
          height: 100%;
          max-height: 100%;
        }
      }
    }

    .product-name {
      display: block;
      font-size: 3.6rem;
      font-weight: 800;
      letter-spacing: -0.1rem;
      margin-bottom: 1rem;
      padding: 0;
      text-align: left;
      min-height: 0;

      @media screen and (max-width: 800px) {
        font-size: 3.5rem;
      }

      @media screen and (max-width: 1000px) {
        font-size: 3.2rem;
      }

      @media screen and (max-width: 600px) {
        font-size: 2.9rem;
        width: calc(100vw - 4rem);
      }
    }

    .product-description {
      font-size: 1.8rem;
      text-align: left;
      max-width: 100%;
      min-height: 0;
      margin-bottom: 1rem;

      @media screen and (max-width: 1000px) {
        text-align: left;
      }
    }

    .product-rate {
      @include justify-content(flex-start);
    }

    .product-price {
      margin: 0 0 0.5rem;
      text-align: left;
    }

    .cart-btn {
      text-align: center;
      margin: 0;
      max-width: 15rem;

      button {
        margin: 0;
      }
    }
  }
}

.author-page {
  .category-article-wrapper {
    margin-top: 2rem;
    text-align: left;

    p {
      font-size: 1.6rem;
      line-height: 2.2rem;
    }

    h4 {
      font-size: 1.6rem;
    }
  }

  h4 {
    margin: 0;
  }

  @media screen and (max-width: 820px) {
    .category-article-list {
      .category-article {
        padding: 0rem 1rem 5rem 1rem;
        margin-right: 0;
      }

      h4 {
        margin: 0;
      }
    }
  }
}

.author-component {
  &.header-type {
    padding: 5rem 0;
    min-height: 20%;

    @media screen and (max-width: 820px) {
      padding: 3rem 0;
    }

    .container {
      display: flex;
      padding: 0 8rem;

      @media screen and (max-width: 1280px) {
        padding: 0 4rem;
      }

      @media screen and (max-width: 820px) {
        padding: 0 2rem;
      }

      @media screen and (max-width: 820px) {
        flex-direction: column;
      }
    }

    h2 {
      font-size: 3.6rem;
      padding-top: 0.5rem;
      line-height: 4rem;
      padding-bottom: 1.5rem;
    }

    h4 {
      text-transform: none;
      letter-spacing: normal;
      font-weight: normal;
      padding-bottom: 0;
      font-size: 1.8rem;
    }

    h2,
    h4 {
      text-align: left;
      color: $color-secondary;
    }

    p {
      text-align: left;
      font-size: 1.8rem;
    }

    .avatar {
      flex: 0 0 130px;
      margin-right: 3rem;

      img {
        object-fit: cover;
        border-radius: 50%;
        height: 130px;
        width: 130px;
      }

      @media screen and (max-width: 820px) {
        margin: 0 auto;
      }
    }

    .bio {
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 820px) {
        text-align: center;
        padding: 0 1rem;

        h2 {
          order: 1;
          margin: 1.5rem auto 0.5rem;
          font-size: 2.4rem;
          line-height: 3rem;
          padding: 0;
          text-align: center;
        }

        h4 {
          order: 2;
          font-size: 1.6rem;
          margin: 0 auto 1.5rem;
          text-align: center;
        }

        p {
          order: 3;
          font-size: 1.6rem;
        }

        .socials {
          order: 4;
        }
      }
    }

    .socials {
      display: flex;
      align-items: center;

      span {
        color: $color-secondary;
        padding-left: 0.5rem;
      }

      a {
        display: flex;
        align-items: center;
        color: $color-secondary;
        padding-right: 1.5rem;
      }
    }
  }

  &.plain-type {
    padding: 5rem 0;
    min-height: 20%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 820px) {
      padding: 3rem 0;
    }
  }
}

.authors-page {
  .header {
    min-height: 25rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h2 {
      color: black;
    }

    p {
      width: 50%;
      margin: 1rem auto;
    }
  }

  .top {
    margin: 0 auto;
    width: 50%;
  }

  .author-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .author-card {
      width: calc(50% - 1rem);
      margin-bottom: 2rem;
    }
  }
}

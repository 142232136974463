.product-block {
  position: relative;
  height: 100%;

  @include align-items(center);
  @include justify-content(flex-end);
  @include flex-direction(column-reverse);
  @include flexbox;

  .content {
    margin-top: 8.5rem;
  }

  .column {
    flex-direction: column;
  }

  .badges-block {
    position: absolute;
    left: 1.5rem;
    top: 1.5rem;
  }

  &.vertical {
    padding-top: 4vh;
  }

  .product-label {
    pointer-events: none;
    position: absolute;
    right: 20%;
    top: 7%;
    width: 30%;
    height: 30%;
    background-image: url('/images/label.svg');
    background-repeat: no-repeat;
    background-size: 100% auto;
    display: block;
  }

  .product-img-block {
    background-color: #faf9fa; //todo:remove
    background: linear-gradient(
      47deg,
      rgba(17, 141, 79, 0.1307116597) 0%,
      rgba(90, 172, 28, 0.1279105392) 100%
    );
    border-radius: 1rem;
    overflow: hidden;

    &:after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }

    .product-img-block-wrapper {
      @include transition(transform 0.45s ease-in-out, opacity 0.3s linear);
      @include transform(scale(1) translateZ(0));

      &:hover {
        @include transition(transform 0.6s ease-out, opacity 0.3s !important);
        @include transform(scale(1.1) translateZ(0));
      }
    }

    a {
      height: 100%;
    }

    img {
      max-height: 83%;
      width: auto;
    }
  }

  .product-info-block {
    margin-top: 1rem;

    @include flex(1);
    @include flex-direction(column);
    @include flexbox;
  }

  .product-name {
    color: $color-primary;
    font-size: 2.2rem;
    letter-spacing: normal;
    padding: 1rem 0;
    text-align: center;
    min-height: 4rem;
  }

  .product-description {
    padding-bottom: 0;
    overflow: hidden;
    text-align: center;
    min-height: 5rem;
    max-width: 30rem;

    @media screen and (max-width: 800px) {
      min-height: 50px;
    }
  }

  a {
    color: $color-primary;

    &.title,
    .name {
      padding: 0;
    }
  }

  .product-rate {
    color: $color-secondary;
    margin: 0 auto 0.5rem;

    @include align-items(center);
    @include flexbox;
    @include justify-content(center);

    .stars {
      margin-right: 0.5rem;
      margin-top: 0.2rem;
    }
  }

  .product-price {
    color: $color-primary;
    font-size: 1.8rem;
    margin: 1.5rem auto;
    padding-bottom: 1rem;
    text-align: center;
    flex-grow: 1;

    @include align-items(flex-end);
    @include flexbox;

    .old-price {
      display: inline-block;
      margin-right: 1rem;
      opacity: 0.5;
      text-decoration: line-through;
    }
  }

  .cart-btn {
    display: block;
    text-align: center;

    margin-top: auto;

    &.button-center {
      margin: 0 auto;
      text-align: center;
    }

    &.button-left {
      text-align: left;
      width: 45%;
    }

    &:hover,
    &:focus {
      animation: none;
    }
  }

  .center-cart {
    display: block;
    margin: 0 auto;
  }
}

.product-img-block {
  position: relative;
  width: 100%;

  .product-img-block-wrapper {
    height: 100%;
    position: absolute;
    width: 100%;

    img {
      max-width: 80%;
    }
  }

  a,
  span {
    display: block;
    width: 100%;
    @include align-items(center);
    @include flexbox;
    @include justify-content(center);
  }
}

@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');

.modal-wrapper {
  .body-modal-quality {
    background-color: #ffffff;
    width: 90rem;

    .text-page {
      padding: 0 0 4rem;

      .container {
        padding: 0 2rem;
      }

      .content {
        margin-top: 0;
        padding-top: 3rem;
      }

      .banner {
        margin: 0;
      }
    }
  }

  .body-modal-subscription {
    border: 1px solid #04bb2f;
    background: #f6fff7;
    width: 80%;
    max-width: 52rem;
    position: fixed;
    border-radius: 1.5rem;
    padding: 2rem;

    .product-image {
      height: 20rem;
      width: auto;
      margin: 0 auto;
      text-align: center;

      img {
        width: auto;
        height: 100%;
        display: block;
        margin: 0 auto;
      }
    }

    .product-title {
      h2 {
        color: #01a401;
        font-size: 3.6rem;
        margin: 1rem 0;
        padding: 0;
        font-weight: 600;
      }

      .bullets {
        list-style: none;
        padding-left: 0;
        margin: 0;

        li {
          font-size: 2rem;

          &:last-child {
            padding-bottom: 1.5rem;
          }

          &:before {
            content: "\2714";
            color: #01a401;
            font-weight: bold;
            margin-right: 1rem;
          }
        }
      }
    }

    .product-actions {
      display: flex;
    }

    .yes-btn,
    .no-btn {
      width: 48%;
      border-radius: 6px;
      font-size: 2em;
      font-family: "Roboto Condensed", sans-serif;
      font-weight: 700;
      font-style: normal;
      padding: 1rem;
      letter-spacing: -0.01em;
      filter: brightness(100%);

      span {
        display: block;
        width: 100%;
        font-size: 0.5em;
        text-transform: none;
        letter-spacing: -0.01em;
      }

      &:hover {
        filter: brightness(95%);
        transition: filter 1s ease;
      }
    }

    .yes-btn {
      background-color: #01a401;
    }

    .no-btn {
      background-color: #ce401e;
      margin-left: 4%;
    }

    @media screen and (max-width: 640px) {
      width: 90%;
      max-width: 45rem;

      .product-image {
        height: auto;
        width: 100%;
        max-width: 25rem;

        img {
          width: auto;
          height: auto;
          max-height: 20rem;
        }
      }

      .product-title {

        h2 {
          font-size: 24px;
        }

        .bullets li {
          font-size: 1.8rem;
        }
      }

      .product-actions {
        flex-wrap: wrap;
        margin: 1rem 0;
      }

      .yes-btn,
      .no-btn {
        width: 100%;
        max-width: 23rem;
        margin: 0 auto;
      }

      .no-btn {
        margin-top: 1rem;
      }
    }
  }
}

.modal-wrapper {
  .cart-component {
    min-height: 100vh;
    padding-bottom: 7.8rem;
    background: #f7f6f7;

    .item-exit {
      transition: opacity 0.5s ease-out, transform 0.5s ease-out;
      opacity: 0;
      transform: scale(0.95);
    }

    .item-enter {
      animation: fadeInScaleUp 4.5s ease-out;
    }

    @keyframes fadeInScaleUp {
      0% {
        opacity: 0;
        transform: scale(0.95);
      }

      100% {
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  &.visible {
    .body-modal-cart-class {
      opacity: 1;
      transform: none;
      transition-duration: 500ms;
    }
  }

  &.hidden {
    .body-modal-cart-class {
      opacity: 0;
      transform: translateX(100%);

      @include transition(all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms);
    }
  }

  .close {
    position: absolute;
    right: 1.5rem;
    top: 1rem;
    z-index: 1201;
  }

  .content-modal-cart-class {
    border-radius: 0;
    padding-bottom: 8rem;
    max-height: 100vh;
    min-height: 100vh;
  }

  .body-modal-cart-class {
    opacity: 0;
    transition-duration: 1s;
    transform: translateX(100%);
    @include transition(all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms);

    position: fixed;
    right: 0;
    top: 0;

    border-radius: 0;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 10px -5px, rgba(0, 0, 0, 0.14) 0px 16px 24px 2px,
      rgba(0, 0, 0, 0.12) 0px 6px 30px 5px;
    height: 100vh;
    max-height: 100vh;
    min-height: 100vh;
    overflow-y: auto;
    padding: 0;

    max-width: 100%;
    width: 56rem;
    z-index: 1200;

    @media screen and (max-width: 820px) {
      width: 100vw;
    }

    @include flex(1 0 auto);
    @include flex-direction(column);
    @include flexbox;

    .cart-title {
      font-size: 1.6rem;
      font-weight: bold;
      letter-spacing: normal;
      text-transform: uppercase;
    }
  }
}

.cart-header {
  background-color: #fff;
  padding: 0 1.5rem;

  .cart-title {
    border-bottom: 1px solid #e6e6e6;
    display: block;
    padding: 2rem 0;
  }
}

.cart-footer {
  background: #fff;
  bottom: 0;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 10px -5px, rgba(0, 0, 0, 0.14) 0px 16px 24px 2px,
    rgba(0, 0, 0, 0.12) 0px 6px 30px 5px;
  padding: 1.5rem;
  position: fixed;
  right: 0;
  text-align: center;
  width: 56rem;

  @media screen and (max-width: 820px) {
    width: 100vw;
  }

  @media screen and (max-width: 640px) {
    .buttons-block {
      @include flex-wrap(wrap);

      .outline {
        margin-bottom: 1.5rem;
        width: 100%;
      }

      button {
        width: 100%;
      }
    }
  }

  .buttons-block {
    @include flexbox;
    @include justify-content(space-between);
  }

  .outline {
    border-radius: 3rem;
    margin: 0 auto;
    width: 48%;
    border: 0;
    cursor: pointer;
    display: inline-block;
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1;
    padding: 1em;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.06em;
    background: #fff;
    color: #5f9f41;
    position: relative;

    @include transition(all 0.3s);
    @include animation(none);

    &::before {
      background: linear-gradient(to right, $color-secondary, $color-accent-light);
      border-radius: 5rem;
      bottom: 0;
      content: '';
      left: 0;
      margin: -2px;
      position: absolute;
      right: 0;
      top: 0;
      z-index: -1;
    }

    &:focus,
    &:hover {
      background: linear-gradient(to right, $color-secondary, $color-accent-light);
      color: #fff;
    }
  }

  button {
    border-radius: 3rem;
    margin: 0 auto;
    width: 48%;
  }
}

.product-list,
.recommended-block .embla-slide {
  background-color: #fff;
  padding: 2rem 1.5rem;

  .badges-block {
    display: none;
  }

  .product-block {
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: 2rem;
    padding-bottom: 1rem;

    @include align-items(flex-start);
    @include flex-direction(row-reverse);
    @include justify-content(flex-end);
  }

  .product-info-block {
    @include flex(1);
  }

  p {
    margin-bottom: 0;
    opacity: 0.5;
  }

  .product-description {
    margin-bottom: 0;
    max-height: none;
    min-height: 0;
    opacity: 1;
    overflow: visible;
    padding-bottom: 0;
    text-align: left;
  }

  .product-name {
    font-size: 1.8rem;
    min-height: 0 !important;
    padding: 0;
    text-align: left;

    a {
      color: $color-primary;
    }
  }

  .product-price {
    float: right;
    font-size: 1.6rem;
    margin: 1.5rem 0;
    padding: 0;
  }

  .product-img-block {
    margin-right: 1rem;

    @include flex(0 0 10rem);

    .product-img-block-wrapper {
      background-position: center;

      @include flexbox;
      @include align-items(center);
    }
  }

  .quantity-selector {
    margin-right: 4rem;

    @include align-items(center);
    @include flexbox;

    .quantity-selector-btn {
      height: 3.6rem;
      padding: 0.5rem;
      width: 3.6rem;

      // &:last-child {
      //     border: $color-accent;
      //     background-color: $color-accent;
      // }

      svg {
        height: 1.5rem;
        width: 1.5rem;

        &.icon-plus g {
          stroke: #fff;
        }

        &.delete-icon {
          height: 1.8rem;
        }
      }
    }

    .current-quantity {
      font-size: 1.6rem;
      width: 3.5rem;
    }
  }
}

.recommended-block {
  padding: 3rem 0 1rem 1.5rem;

  h3 {
    color: $color-primary;
    font-size: 1.6rem;
  }

  .embla-dots {
    display: none;
    width: calc(100% - 8rem);
  }

  .embla-slide {
    border-radius: 0.4rem;
    margin-right: 2rem;
    padding: 1rem 1.5rem 1rem 0;

    @include flex(0 0 30rem);

    .product-block {
      border: 0;
      margin: 0;
      padding: 0;

      @include align-items(center);
    }

    .product-name {
      font-size: 1.5rem;
    }

    .product-price {
      float: none;
      margin: 0;
      text-align: left;
    }

    .product-img-block {
      @include flex(0 0 8rem);
    }

    .add-btn {
      background-color: $color-accent;
      border-radius: 100%;
      border: $color-accent;
      height: 3.6rem;
      margin-left: 2rem;
      padding: 0.5rem;
      width: 3.6rem;

      @include align-items(center);
      @include flexbox;
      @include justify-content(center);

      svg {
        height: 1.5rem;
        width: 1.5rem;

        &.icon-plus g {
          stroke: #fff;
        }
      }
    }
  }
}

.code-block {
  padding: 2rem 1.5rem;
  position: relative;

  input {
    border: 0;
  }

  .link {
    color: $color-accent;
    position: absolute;
    right: 3rem;
    top: 2.8rem;
  }
}

.center {
  display: flex;
  justify-content: center;
}

.subtotal-block {
  margin-bottom: 2rem;
  padding: 0 1.5rem;

  .subtotal-wrapper {
    border-bottom: 1px solid #e6e6e6;
    border-top: 1px solid #e6e6e6;
    padding: 1.5rem 0;

    @include justify-content(space-between);
    @include flexbox;

    h3 {
      color: $color-primary;
      font-size: 1.6rem;
      padding: 0;
    }

    span {
      font-weight: bold;
    }
  }
}
.products-store-list {
  margin-left: calc(-7% / 6);
  margin-right: calc(-7% / 6);

  @media screen and (max-width: 460px) {
    margin-right: 0;
    margin-left: 0;
  }

  @include flex-wrap(wrap);
  @include flexbox;
  @include justify-content(center);

  .product-block {
    min-height: 47vh;
    height: 100%;
    margin-bottom: 6rem;
    margin-right: calc(7% / 6);
    margin-left: calc(7% / 6);
    position: relative;
    width: 31%;

    @media screen and (max-width: 780px) {
      width: 45%;
      margin-right: calc(10% / 4);
      margin-left: calc(10% / 4);
    }

    @media screen and (max-width: 460px) {
      width: 100%;
      margin-right: 0;
      margin-left: 0;
    }
  }
}

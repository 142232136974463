.product-faqs {
  margin: 3rem 0;

  .container {
    max-width: 800px;
  }

  .faq-text {
    @include justify-content(center);

    u {
      color: $color-secondary;
      font-weight: 600 !important;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .product-icons-list {
    margin: 10px auto;
    padding-left: 0;
  }

  h2 {
    text-align: center;
    color: $color-primary;
  }

  .quality-statement-button {
    width: 20%;
    display: inline-block;
    text-align: center;

    @media screen and (max-width: 933px) {
      margin: 10px auto;
      display: block;
    }
  }

  .img {
    display: block;
    margin: -1rem auto 2rem;
    min-width: 15rem;
  }
}

@import 'global.variables';
@import 'reset.module';
@import 'header.module';
@import 'embla.module';
@import 'text-page.module';
@import 'faqs-page.module';
@import 'contacts.module';
@import 'collapse.module';
@import 'footer.module';
@import 'modal.module';
@import 'accordion.module';
@import 'return.page.module';
@import 'gvInputs.module';

@import 'categories.module';
@import 'image-carousel.module';
@import 'search.module';
@import 'product-block-base.module';
@import 'article.module';
@import 'category-articles-list.module';
@import 'category-products-list.module';

@import 'join-block.module';

@import 'dropdown.module';
@import 'testimonials.module';
@import 'author.module';

body {
  .mp-popup-main-wrapper {
    &:first-child {
      display: block !important;

      &.show {
        display: block !important;
      }

      &.hide {
        display: none !important;
      }
    }

    p {
      //font-family: $font-general !important;
      font-size: 1.6rem;

      strong {
        font-size: 3rem;
        line-height: normal;
      }
    }

    label {
      //font-family: $font-general !important;
      padding: 0 !important;
      color: $color-primary !important;
    }

    button {
      //font-family: $font-general !important;
      font-size: 1.5rem !important;
      letter-spacing: 0.06em !important;

      &:focus,
      &:hover {
        opacity: 0.95;
      }
    }

    #popup-form {
      padding-bottom: 0 !important;
    }

    #title-h4 p {
      font-weight: 700;
    }

    #title-h2 p {
      font-size: 3rem;
      margin-top: 0.5rem;

      @media screen and (max-width: 360px) {
        font-size: 2.6rem;
      }
    }

    div[class^='popup'][class*='-container '] {
      margin: 1rem;

      @media screen and (max-width: 768px) {
        max-width: 60rem;

        div[class$='-row-content'] {
          max-width: 100%;
          margin-top: 1.5rem;
          margin-bottom: 1.5rem;
        }
      }
    }
  }

  .mp-popup-overlay.overlay {
    background: rgba(0, 0, 0, 0.6) !important;
  }

  #embedded_form_23,
  #embedded_form_25,
  #embedded_form_27,
  #embedded_form_29 {
    min-height: 200px !important;
    margin-bottom: 1.5rem;

    p {
      //font-family: $font-general !important;
      font-size: 1.6rem;
    }

    label {
      //font-family: $font-general !important;
      padding: 0 !important;
      color: $color-primary !important;
    }

    button {
      margin-top: 0;
      //font-family: $font-general !important;
      font-size: 1.5rem !important;
      letter-spacing: 0.06em !important;

      &:focus,
      &:hover {
        opacity: 0.95;
      }
    }
  }
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: #333;
  -webkit-text-size-adjust: 100%;
  //font-family: $font-general;
  font-size: 10px;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  background-color: #fff !important;
  color: $color-primary !important;
  //font-family: $font-general;
  font-size: 1.6rem;
  letter-spacing: -0.01em;
  line-height: normal;
  margin: 0;
  padding: 0;
  // height: 100%;
}

.no-scroll {
  overflow: hidden;
}

.banner {
  margin-bottom: 4rem;
  overflow-x: hidden;

  img {
    width: 100vw;
    height: auto;
  }
}

h1,
h2,
h3,
h4 {
  color: $color-primary;
  font-weight: bold;
  letter-spacing: -0.1rem;
  margin: 0;
}

h1 {
  color: $color-primary;
  font-size: 4.4rem;
  line-height: 1.1;
  padding-bottom: 2rem;
  padding-top: 2rem;
  position: relative;
  width: 100%;

  .name {
    color: $color-primary;
  }

  &.title {
    color: $color-primary;

    &:before {
      content: '';
      display: block;
      width: 4rem;
      height: 0.4rem;
      background-color: $color-secondary;
      margin-bottom: 1rem;
    }
  }

  &.title-center {
    text-align: center;
    padding: 5rem 0;
  }
}

h2 {
  font-size: 3.2rem;
  padding: 2rem 0;

  .name {
    color: $color-primary;
    padding: 7rem 0;
  }

  &.title {
    color: $color-primary;
    padding: 7rem 0;

    &:before {
      content: '';
      display: block;
      width: 4rem;
      height: 0.4rem;
      background-color: $color-secondary;
      margin-bottom: 1rem;
    }
  }
}

h3 {
  font-size: 2.4rem;
  letter-spacing: normal;
  padding-bottom: 2rem;

  a:hover,
  &:focus {
    text-decoration: none;
    color: $color-secondary-dark;
  }
}

h4 {
  font-size: 1.6rem;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  padding-bottom: 1rem;
  opacity: 0.7;

  a:hover,
  &:focus,
  &:active {
    @include animation(none);
  }
}

img {
  image-rendering: -webkit-optimize-contrast;
}

p {
  font-size: 1.6rem;
  line-height: 2.2rem;
  margin-bottom: 2rem;
  position: relative;
}

strong {
  font-weight: bold;
}

a {
  color: $color-secondary;
  text-decoration: none;

  &:focus,
  &:hover {
    @include animation(animated-underline 0.3s ease-in-out forwards);
  }
}

sup {
  position: relative;
  line-height: 0;
  vertical-align: baseline;
  top: -0.5rem;
  color: $color-secondary;
  font-weight: bold;
}

button {
  background-color: $color-accent;
  border-top-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
  border: 0;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1;
  margin: 1rem 0;
  padding: 1em;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.06em;

  @include transition(all 0.3s);

  &:focus,
  &:hover {
    background-color: $color-secondary;
    color: #fff;
    text-decoration: none;
    border-top-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-right-radius: 2rem;
    border-bottom-left-radius: 2rem;
  }

  &:focus {
    outline: none;
    text-decoration: none;
  }

  &.outline {
    background: #fff;
    color: #5f9f41;
    position: relative;

    &::before {
      background: linear-gradient(to right, $color-secondary, $color-accent-light);
      border-radius: 5rem;
      bottom: 0;
      content: '';
      left: 0;
      margin: -2px;
      position: absolute;
      right: 0;
      top: 0;
      z-index: -1;
    }

    &:focus,
    &:hover {
      background: linear-gradient(to right, $color-secondary, $color-accent-light);
      color: #fff;
    }
  }

  &.light {
    background-color: #fff;
    border: 0;
    color: $color-secondary;

    &:focus,
    &:hover {
      background: linear-gradient(to right, $color-secondary, $color-accent-light);
      color: #fff;
    }
  }

  &.colored {
    background: linear-gradient(to right, $color-secondary, $color-accent-light, $color-secondary);
    background-size: 300% 100%;

    &:focus,
    &:hover {
      background-position: 100% 0;
    }
  }

  &.link {
    padding: 0;
    border: 0;
    background-color: transparent;
    color: $color-secondary;
    font-weight: bold;
    text-decoration: underline;
    border-radius: 0;
    text-transform: none;
    letter-spacing: normal;

    &:hover {
      text-decoration: none;
    }
  }

  &:disabled {
    opacity: 0.5;
    background-position: 0 0 !important;
    cursor: default;
  }

  &.disabled-item {
    opacity: 1;
    background-position: 0 0 !important;
    background: #657881;
    cursor: default;
    min-width: 13rem;

    &:hover {
      border-top-left-radius: 2rem;
      border-bottom-right-radius: 2rem;
      border-bottom-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}

.fixed-btn {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  background-color: #488cbb;
  border-radius: 0.5rem;
  border: 0;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1;
  margin: 1rem;
  padding: 0.7em 1em;
  text-align: center;
  z-index: 100;

  @include transition(all 0.3s);

  &:focus,
  &:hover {
    background-color: #3f7faa;
    color: #fff;
    text-decoration: none;
  }
}

input {
  height: 5rem;
}

input,
select,
textarea {
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #e6e6e6;
  font-size: 1.6rem;
  font-weight: 400;
  padding: 1.5rem;
  width: 100%;
  outline: none;
  display: block;

  @include transition(all 0.3s);

  &:focus,
  &:visited {
    background-color: #fff;
    border-color: $color-secondary;
  }

  &+.invalid-feedback {
    display: none;
  }

  &.error+.invalid-feedback {
    display: block;
  }

  &.error {
    border-color: #f44336;
    position: relative;
  }
}

textarea {
  width: 100%;
  max-width: 100%;
}

select.form-control {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: #fff url('/images/icons/arrow-down.svg') no-repeat 98% center;
  background-size: 1.4rem;
}

.custom-select {
  input {
    height: 3.8rem;
  }

  .select__control {
    border: none;
    cursor: pointer;
    min-height: auto;
  }

  .select__indicator-separator {
    display: none;
  }
}

.checkmark {
  background-color: #fff;
  border-radius: 5px;
  height: 2.5rem;
  position: absolute;
  top: -0.3rem;
  left: 0;
  width: 2.5rem;

  &::after {
    content: '';
    display: none;
    position: absolute;
  }
}

.checkbox-wrapper {
  cursor: pointer;
  font-size: 1.6rem;
  font-weight: 400;
  display: block;
  margin-bottom: 3rem;
  padding-left: 3.5rem;
  position: relative;
  @include user-select(none);

  input {
    cursor: pointer;
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0;

    &:checked~.checkmark {
      background-color: $color-secondary;

      &::after {
        display: block;
      }
    }
  }

  &:hover input~.checkmark {
    background-color: $color-secondary;
  }

  .checkmark::after {
    border: solid #fff;
    border-width: 0 3px 3px 0;
    height: 1.25rem;
    left: 0.9rem;
    top: 0.5rem;
    width: 0.7rem;
    @include transform(rotate(45deg));
  }
}

label {
  color: $color-primary;
  display: block;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

label span {
  color: $color-secondary;
}

::placeholder {
  color: $color-primary;
  opacity: 0.5;
}

:-ms-input-placeholder {
  color: $color-primary;
}

::-ms-input-placeholder {
  color: $color-primary;
}

input.address-input {
  background-image: url('/images/icons/location-icon.svg');
  background-position: 1.7rem center;
  background-repeat: no-repeat;
  background-size: auto 2rem;
  color: $color-secondary;
  padding-left: 4rem;
  font-size: 14.5px;
}

input.email-input {
  background-image: url('/images/icons/email-icon.svg');
  background-position: 1.7rem center;
  background-repeat: no-repeat;
  background-size: auto 1.3rem;
  color: $color-secondary;
  margin: 0;
  min-width: 30rem;
  padding-left: 4rem;
  width: 100%;
  font-size: 14.5px;
}

.address-input {
  .select__control {
    border-color: transparent;
    border-radius: 5px;

    &.select__control--is-focused {
      border-color: #2684ff;
    }

    input {
      height: 3.6rem;
      font-size: 14.5px !important;
      text-align: left;
    }
  }

  .select__value-container {
    background-image: url('/images/icons/location-icon.svg') !important;
    background-position: 1.5rem center !important;
    background-repeat: no-repeat !important;
    background-size: auto 2rem !important;
    padding-left: 4rem !important;
  }
}

.select__menu {
  z-index: 1000 !important;
  position: absolute !important;
}

.recaptcha {
  margin-bottom: 2rem;
}

.error-popup {
  background-color: #f44336;
  color: #fff;
  padding: 2rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;

  &.hide {
    display: none;
  }

  .close-btn {
    color: #fff;
    cursor: pointer;
    float: right;
    font-size: 2.2rem;
    line-height: 2rem;
    @include transition(all 0.3s);

    &:hover {
      color: $color-primary;
    }
  }
}

.invalid-feedback {
  color: #f44336;
  display: none;
  font-size: 1.4rem;
  font-weight: normal;
}

.form-control-note {
  display: block;
  font-size: 1.4rem;
  font-style: italic;
  position: relative;
  margin-bottom: 0.5rem;
  top: -1rem;
}

.form-error-message {
  color: $color-accent;
  display: block;
  font-size: 1.4rem;
  font-style: italic;
  position: relative;

  &.center {
    max-width: 64rem;
    text-align: center;
    margin: 0 auto 2rem;
    width: 100%;
  }
}

.form-message {
  color: $color-secondary;
  display: block;
  font-size: 1.4rem;
  font-style: italic;
  position: relative;

  &.light {
    color: #fff;
  }
}

.align-center {
  text-align: center;
}

.two-rows-wrapper {
  width: 100%;

  @include flexbox;
  @include justify-content(space-between);
  @include flex-wrap(wrap);

  &>* {
    width: 48%;
  }
}

table {

  th,
  td {
    padding: 1.5rem;
    font-size: 1.4rem;
    vertical-align: middle;
  }
}

.table-responsive {
  margin: 0 2rem;
  display: block;
  width: calc(100% - 4rem);
  overflow-x: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.container {
  margin: 0 auto;
  max-width: 1360px;
  padding: 0 4rem;

  @media screen and (max-width: 820px) {
    padding: 0 2rem;
  }

  .container {
    padding: 0;
  }
}

a.anchor {
  display: block;
  position: relative;
  top: -15rem;
  visibility: hidden;
}

.error-page {
  height: calc(100vh - 30rem);

  .container {
    height: 100%;
    text-align: center;

    @include flexbox;
    @include justify-content(center);
    @include align-items(center);
  }

  .error-wrapper {
    margin-top: -2rem;
    position: relative;

    h1 {
      padding-bottom: 1rem;
    }
  }
}

.preloader {
  font-weight: bold;
  width: 100%;

  @include flexbox;
  @include align-items(center);
  @include justify-content(center);
  @include align-self(stretch);

  span {
    background-color: #fff;
    border-radius: 4px;
    display: inline-block;
    height: 4px;
    margin-left: 2rem;
    vertical-align: top;
    width: 4px;

    &:nth-child(1) {
      @include animation(loading-dots 1s ease-in-out 0ms infinite);
    }

    &:nth-child(2) {
      margin-left: 4px;
      @include animation(loading-dots 1s ease-in-out 160ms infinite);
    }

    &:nth-child(3) {
      margin-left: 4px;
      @include animation(loading-dots 1s ease-in-out 320ms infinite);
    }
  }

  &.inverse {
    color: $color-secondary;

    span {
      background-color: $color-secondary;
    }
  }
}

.loader {
  background-color: rgba(255, 255, 255, 75%);
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;

  .outer,
  .middle,
  .inner {
    border: 3px solid transparent;
    border-top-color: $color-secondary;
    border-right-color: $color-secondary;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
  }

  .outer {
    width: 3.5em;
    height: 3.5em;
    margin-left: -1.75em;
    margin-top: -1.75em;
    @include animation(spin 2s linear infinite);
  }

  .middle {
    width: 2.1em;
    height: 2.1em;
    margin-left: -1.05em;
    margin-top: -1.05em;
    @include animation(spin 1.75s linear reverse infinite);
  }

  .inner {
    width: 0.8em;
    height: 0.8em;
    margin-left: -0.4em;
    margin-top: -0.4em;
    @include animation(spin 1.5s linear infinite);
  }
}

.scroll-button {
  background: #fff url('/images/icons/arrow-top.svg') no-repeat 98% center;
  background-size: 100%;
  border-radius: 4rem;
  bottom: 2rem;
  box-shadow: 0 1rem 2.5rem rgba(14, 19, 88, 8%);
  cursor: pointer;
  font-size: 3rem;
  height: 5.6rem;
  position: fixed;
  left: 2rem;
  width: 4rem;
  z-index: 299;

  &:hover {
    box-shadow: 0 1rem 2.5rem rgba(14, 19, 88, 18%);

    @include transition(all 0.3s);
  }
}

.chat-iframe-wrap {
  right: 2rem !important;
  bottom: auto !important;
  left: auto !important;
  top: 10rem !important;
  border-radius: 4px !important;
  max-width: 400px !important;
}

@media screen and (min-width: 1500px) {
  .chat-iframe-wrap {
    right: calc(50% - 725px) !important;
  }
}

@media screen and (max-width: 991px) {
  h2 {
    font-size: 3rem;
  }
}

@media screen and (max-width: 820px) {
  a.anchor {
    top: -2rem;
  }

  .banner {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 580px) {
  h1 {
    font-size: 4rem;
  }

  h2 {
    font-size: 2.8rem;
  }

  h3 {
    font-size: 2rem;
  }

  .two-rows-wrapper>* {
    width: 100%;
  }

  .chat-iframe-wrap {
    left: 50% !important;
    transform: translateX(-50%);
  }
}

@media screen and (max-width: 360px) {
  h1 {
    font-size: 3.2rem;
  }

  h2 {
    font-size: 2.4rem;
  }

  h3 {
    font-size: 1.8rem;
  }
}

.hidden {
  display: none;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.gradient {
  background: linear-gradient(47deg,
      rgba(17, 141, 79, 0.13071165966386555) 0%,
      rgba(90, 172, 28, 0.1279105392156863) 100%);
}
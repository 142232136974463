header {
  background-color: #fff;
  .nav-menu-list {
    background: #fff;
  }
  .submenu-wrapper {
    background-color: #fff;
  }
  .cart-btn {
    display: inline-block;
    height: 4rem;
    margin: -0.5rem 0 0 2rem;
    text-decoration: none !important;
    width: 4.2rem;

    span {
      font-size: 1.4rem;
      position: relative;
      top: -0.2rem;
    }

    &:hover {
      opacity: 0.7;
    }

    .cart-btn-items {
      position: absolute;
      top: 18%;
      width: 4.2rem;
    }
  }
}

main.article {
  section.article-banner-section {
    background: linear-gradient(
      47deg,
      rgba(17, 141, 79, 0.1307116597) 0%,
      rgba(90, 172, 28, 0.1279105392) 100%
    );

    div.container {
      position: relative;

      // .article-panel-wrapper {
      //   background-color: #c0c0c0;
      //   position: fixed;
      //   top: 0;
      //   width: 100%;
      //   z-index: 100;
      // }
    }
  }
}

.category-article {
  .category-article-img {
    background-color: #fafafa;
    background-image: url('/images/gv-icon.svg');
  }
}

.article-info {
  color: #696f68;
}
.article-options {
  background: #ffffff;
}

.article-content {
  border-top: 1px solid #e6e6e6;
  .product-wrapper {
    background: #faf9fa;
  }
}

.article-content-wrapper {
  .ref {
    border-top: 1px solid #e6e6e6;
  }
  hr {
    border-top: 1px solid #e6e6e6;
  }
}

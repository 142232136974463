.benefits,
.search-result {
  .benefits-wrapper {
    background-color: #ffffff;
    background: linear-gradient(
      47deg,
      rgba(17, 141, 79, 0.13071165966386555) 0%,
      rgba(90, 172, 28, 0.1279105392156863) 100%
    );
    padding: 6rem 0;

    @media screen and (max-width: 820px) {
      padding: 3rem 0 14rem;
    }

    @media screen and (max-width: 380px) {
      padding-bottom: 10rem;
      padding-top: 1rem;
    }
  }

  .container {
    position: relative;
  }

  h2 {
    color: $color-accent;
    font-size: 3.6rem;
    font-weight: 800;
    letter-spacing: -0.1rem;
  }

  .benefits-block {
    position: relative;
    width: 100%;

    @include align-items(center);
    @include flexbox;
    @include justify-content(space-between);

    p {
      font-size: 1.8rem;
      line-height: 1.3;
    }

    @media screen and (max-width: 1520px) {
      padding-right: 14rem;

      @include justify-content(flex-start);
    }

    @media screen and (max-width: 820px) {
      padding-right: 0;

      @include flex-direction(column);

      &.benefits-wrapper-text {
        margin-bottom: 5rem;
      }
    }
  }

  .benefits-wrapper-img {
    margin-top: -5%;
    margin-left: 5%;
    position: relative;
    width: 25rem;

    @include flex(0 0 25rem);

    @media screen and (max-width: 1120px) {
      margin-top: -5%;
      width: 20rem;

      @include flex(0 0 20rem);
    }

    @media screen and (max-width: 820px) {
      margin-left: -14rem;
      margin-top: 2rem;

      @include flex(none);
    }

    @media screen and (max-width: 380px) {
      margin-left: -30vw;
      max-width: 50vw;
    }

    img {
      height: auto;
      max-height: none;
      width: 100%;
    }
  }

  .product-img-wrapper {
    background-image: url('/images/home/product/bottle-shadow.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto 100%;
    height: 35rem;
    position: absolute;
    top: 3rem;
    left: 5rem;
    width: 35rem;

    @media screen and (max-width: 1120px) {
      height: 28rem;
      left: 4.5rem;
      width: 28rem;
    }

    @media screen and (max-width: 820px) {
      bottom: -8rem;
    }

    @media screen and (max-width: 380px) {
      bottom: -20vw;
      height: 60vw;
      width: 60vw;
    }

    img {
      position: absolute;
      right: 5rem;
      top: 1rem;
      width: 18rem;

      @include transform(rotate(38deg));

      @media screen and (max-width: 1120px) {
        width: 14rem;
      }

      @media screen and (max-width: 380px) {
        right: 10vw;
        top: 3vw;
        width: 32vw;
      }
    }
  }
}

.benefits-icons-section {
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 2rem;

  .container {
    max-width: 1480px;
  }
}

.benefits-icons-block {
  padding: 2rem 0 4rem;

  @include flexbox;

  @media screen and (max-width: 1120px) {
    width: 100%;
  }

  @media screen and (max-width: 820px) {
    @include flex-wrap(wrap);
  }

  @media screen and (max-width: 600px) {
    padding-top: 0;
  }

  .benefits-icons-item {
    color: $color-primary;
    font-weight: bold;
    text-align: center;
    width: 25%;

    &:hover {
      animation: none;
      color: $color-accent;

      sup {
        color: $color-accent;
      }
    }

    sup {
      color: $color-primary;
    }

    &:before {
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%;
      content: '';
      display: block;
      height: 5rem;
      margin: 0 auto 1rem;
      width: 5rem;

      @media screen and (max-width: 820px) {
        height: 4rem;
        width: 4rem;
      }
    }

    &:nth-child(1) {
      &:before {
        background-image: url('/images/icons/home/drop-icon.svg');
      }
    }

    &:nth-child(2) {
      &:before {
        background-image: url('/images/icons/home/lab-icon.svg');
      }
    }

    &:nth-child(3) {
      &:before {
        background-image: url('/images/icons/home/shield-icon.svg');
      }
    }

    &:nth-child(4) {
      &:before {
        background-image: url('/images/icons/home/non-gmo-icon.svg');
      }
    }

    @media screen and (max-width: 820px) {
      margin-bottom: 4rem;
      padding: 0 1rem;
      width: 50%;

      &:nth-child(3),
      &:nth-child(4) {
        margin-bottom: 0;
      }
    }

    @media screen and (max-width: 380px) {
      font-size: 1.4rem;
    }
  }
}

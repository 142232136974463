.dropdown-container {
  margin: 1rem 0;
  position: relative;

  .dropdown-header {
    border: 1px solid #e6e6e6;
    border-radius: 2.6rem;
    padding: 0.5rem 4rem 0.5rem 1rem;
    background: #fff url('/images/icons/arrow-down.svg') no-repeat 96% center;
    background-size: 1.4rem;

    @include flexbox;
    @include align-items(center);
  }

  .dropdown-list {
    padding: 0;
    margin: 0.5rem 0 0;
    background: #ffffff;
    border: 2px solid #e6e6e6;
    box-sizing: border-box;
    font-weight: 500;
    border-radius: 1rem;
    position: absolute;
    width: 100%;
    z-index: 5;
    overflow: hidden;
  }

  .list-item {
    list-style: none;
    padding: 0.5rem 2rem 0.5rem 1rem;

    @include flexbox;
    @include align-items(center);

    &:hover {
      background-color: $color-light-bg;
    }
  }

  .set-img-wrapper {
    width: auto;
    min-width: 5rem;
    height: 3rem;
    position: relative;
    display: inline-block;
    margin-right: 1rem;

    @include flexbox;
    @include justify-content(center);

    img {
      height: 100%;
      width: auto !important;
      max-width: auto;
      min-width: auto;
      position: relative !important;
    }

    & + span {
      @include flex(1);
    }
  }
}

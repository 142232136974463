@import 'variables';

@import '@hyex/ui/styles/hyex/globals.module.scss';

@import 'product-block-base';
@import 'quantity-selector-base';
@import 'product-tag';

@import 'article';
@import 'cart-page';
@import 'header';

@import 'benefits';
@import 'guarantee';
@import 'home';
@import 'learn';
@import 'product';
@import 'products';
@import 'product-additional-info';
@import 'product-banner';
@import 'product-faqs';
@import 'product-allergens';
@import 'product-key-ingredients';
@import 'product-main-details';
@import 'products-list';
@import 'product-reviews';
@import 'stars';
@import 'shopping-cart-modal';

main {
  min-height: calc(100vh - 20rem);
  overflow-x: hidden;
  position: relative;
}

.content {
  margin-top: 8.5rem;
}

.text-overflow {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-bottom: 0.5rem;
  margin-bottom: 0.25rem;
  &.xl {
    -webkit-line-clamp: 12;
  }
  &.pb-0 {
    padding-bottom: 0 !important;
  }
}

.w-auto {
  width: auto;
}

.h-auto {
  height: auto;
}

$gradient-start-color: rgba(17, 141, 79, 0.131);
$gradient-end-color: rgba(90, 172, 28, 0.128);

@mixin linear-gradient-angled($angle, $start-color, $end-color) {
  background: linear-gradient($angle, $start-color 0%, $end-color 100%);
}

.basic-green-gradient {
  @include linear-gradient-angled(47deg, $gradient-start-color, $gradient-end-color);
}

.uppercase {
  text-transform: uppercase;
}

.accordion {
  button {
    background-color: transparent;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0;
    color: $color-primary;
    font-size: 1.8rem;
    line-height: 1.3;
    margin: 0;
    padding: 0;
    text-align: left;
    text-transform: none;
    letter-spacing: normal;
    text-wrap: pretty;
    width: 100%;

    @include align-items(center);
    @include flexbox;
    @include justify-content(space-between);

    &:hover .accordion-icon {
      background-color: $color-light-bg;
    }

    &:focus,
    &:hover {
      background-color: transparent;
      border-bottom-right-radius: 0;
      border-top-left-radius: 0;
      color: $color-primary;
    }
  }

  h3 {
    margin-bottom: 0;
  }

  p {
    margin: 0 0 3rem;
  }

  hr {
    opacity: 0.3;
  }

  .accordion-icon {
    border-radius: 4rem;
    height: 4rem;
    margin-left: 1rem;
    position: relative;
    right: 0;
    width: 4rem;

    svg {
      height: 1.5rem;
      width: 1.5rem;
    }

    @include align-items(center);
    @include flexbox;
    @include justify-content(center);
    @include transition(all 0.3s);
  }

  span[data-open='true'] .accordion-icon {
    @include transform(rotate(-45deg));
  }
}

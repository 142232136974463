.product-reviews {
    padding-bottom: 4rem;
    
    .container {
        position: relative;
    }

    h2 {
        color: $color-primary;
    }

    p i {
        display: inline-block;
        font-size: 2.1rem;
        line-height: 1.3;
        margin-top: 2rem;
    }

    .stars {
        @include transform(scale(1.4));
        margin-left: 2rem;
        margin-right: 2rem;
    }

    .reviews-title {
        border-bottom: 1px solid #e6e6e6;
        margin-bottom: 1rem;
    }

    .customer {
        display: block;
        margin: 0 auto;

        @include flexbox;
        @include align-items(center);
        @include flex-direction(column);

        .stars {
            margin-bottom: 1rem;
        }
    }

    .photo-wrapper {
        background-color: #e6e6e6;
        border-radius: 100%;
        display: inline-block;
        height: 6rem;
        margin-right: 1rem;
        overflow: hidden;
        width: 6rem;
        margin-bottom: 1rem;
    }

    .embla-slide {
        @include flex(0 0 100%);

        .reviews-item {
            margin: 3rem auto;
            max-width: 70rem;
            text-align: center;

            @include flexbox;
            @include flex-direction(column);
            @include align-items(center);
        }
    }

    .embla-buttons {
        position: absolute;
        right: 3rem;
        top: 2rem;

        @media screen and (max-width: 820px) {
            right: 1rem;
        }

        .embla-button {
            margin: 0 1rem;
        }
    }

    @media screen and (max-width: 600px) {
        .reviews-title {
            border: 0;
            text-align: center;
        }

        .embla-buttons {
            margin: 0 auto;
            position: relative;
            right: auto;
            top: auto;
            width: 12rem;
        }
    }
}

.product-badge {
  pointer-events: none;
  font-size: 1.2rem;
  font-weight: bold;
  background-color: #f22a4d;
  color: #ffffff;
  padding: 0.2rem 0.5rem;
  line-height: 2;
  text-transform: uppercase;
  border-radius: 3px;

  float: left;
  clear: both;
  margin-bottom: 0.5rem;
  &.discontinued {
    background-color: #657881;
  }
}

.product-img-block {
  position: relative;
  width: 100%;

  .product-img-block-wrapper {
    height: 100%;
    position: absolute;
    width: 100%;

    img {
      max-width: 80%;
    }
  }

  a,
  span {
    display: block;
    width: 100%;
    @include align-items(center);
    @include flexbox;
    @include justify-content(center);
  }
}
